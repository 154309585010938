import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { FormikWizardState, IEligibilityFormProfileFYW } from "../types";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  ELIGIBILITY_MESSAGE,
  PARTIAL_ELIGIBILITY_ERROR_CODE,
  USER_PAYROLL_STATUS,
  USER_STATUS,
  UserContext,
} from "../../../context/UserProvider";
import { getIn, useFormikContext } from "formik";

import FooterCard from "../components/FooterCard";
import Header from "../components/Header";
import StatusModal from "../components/StatusModal";
import FwEligibilityModal from "../../account/FwEligibilityModal";
import { ImpersonationContext } from "../../../context/ImpersonationProvider";
import useRouteQuery from "../../../hooks/useRouteQuery";
import axiosInstance from "../../../axios.instance";
import Select, { OptionsType, ValueType } from "react-select";
import { CoverageTypeOption } from "../../../types";
import { initialCoverageType } from "../model/InitialState";
import { AccountContext } from "../../../context/AccountProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useConfig } from "../../../configuration/useConfig";

const Eligibility = ({ next }: { next?: () => void }) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    setFieldTouched,
  } = useFormikContext<FormikWizardState<IEligibilityFormProfileFYW>>();

  const { user, setUser } = useContext(UserContext);
  const { member } = useContext(AccountContext);
  const { clientConfig, setClient } = useConfig();
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [clientCode, setClientCode] = useState("");
  const [cardOneRef] = useState<HTMLDivElement | null>(null);
  const [, setRefs] = useState<(HTMLDivElement | null)[]>([]);
  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [impersonateLockForm, setImpersonateLockForm] =
    useState<boolean>(false);
  const { isImpersonated } = useContext(ImpersonationContext);
  const query = useRouteQuery();
  const [isDependentToPrimary, setIsDependentToPrimary] = useState<boolean>();
  const [showRegistrationModel, setShowRegistrationModel] = useState(false);
  const [isCoverageTypeValid, setIsCoverageTypeValid] = useState(true);
  const [isBSCA, setIsBSCA] = useState(false);
  const [loadingFields, setLoadingFields] = useState(false);
  const [showJWTSSOModal, setShowJWTSSOModal] = useState(true);
  const [email, setEmail] = useState("");

  const selectRef = useRef<Select<CoverageTypeOption>>(null);
  const [coverageTypeSelected, setCoverageTypeSelected] =
    useState<ValueType<CoverageTypeOption, false>>(null);
  const [coverageTypeOptions] =
    useState<OptionsType<CoverageTypeOption>>(initialCoverageType);

  const customStyles = {
    menuPortal: (base) => ({
      ...base,
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: 150, // Limit the visible options
      overflowY: "scroll", // Enable scrolling
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: !isCoverageTypeValid
        ? "red"
        : state.isFocused
        ? "#86b7fe"
        : provided.borderColor,
      outline: state.isFocused ? 0 : provided.outline,
      boxShadow: !isCoverageTypeValid
        ? "0 0 0 0.25rem rgba(255, 0, 0, 0.25)"
        : state.isFocused
        ? "0 0 0 0.25rem rgba(13, 110, 253, 0.25)"
        : provided.boxShadow,
    }),
  };

  useEffect(() => {
    setRefs([cardOneRef]);
  }, [cardOneRef]);

  useEffect(() => {
    if (query.get("isSSO") === "true") {
      setUser({ isSSO: true });
    }
  }, []);
  //set impersonate value from browser parameter
  useEffect(() => {
    setImpersonateLockForm(isImpersonated && window.location.search.length > 0);
    if (isImpersonated && query.get("firstName")) {
      const dobString = query.get("dateOfBirth");
      const dob = dobString ? new Date(`${dobString}T00:00:00Z`) : new Date(0);
      const year = dob.getUTCFullYear();
      const month = ("0" + (dob.getUTCMonth() + 1)).slice(-2).toString();
      const day = ("0" + dob.getUTCDate()).slice(-2).toString();

      setFieldValue("eligibility.firstName", query.get("firstName"));
      setFieldValue("eligibility.lastName", query.get("lastName"));
      setFieldValue("eligibility.year", year);
      setFieldValue("eligibility.day", day);
      setFieldValue("eligibility.month", month);
      setFieldValue(
        "eligibility.associateIdentificationNumber",
        query.get("subscriberId")
      );
      setFieldValue("eligibility.email", query.get("email"));
      setFieldValue("eligibility.subscriberId", query.get("subscriberId"));
      setFieldValue("eligibility.zip", query.get("zip"));
      setFieldTouched("eligibility.name", true);
    } else if (user.isSSO) {
      const dobString = query.get("dateOfBirth");
      const dob = dobString ? new Date(`${dobString}T00:00:00Z`) : new Date(0);
      const year = dob.getUTCFullYear();
      const month = ("0" + (dob.getUTCMonth() + 1)).slice(-2).toString();
      const day = ("0" + dob.getUTCDate()).slice(-2).toString();
      const JWTCoverageType: CoverageTypeOption | null = query.get(
        "coverageType"
      )
        ? {
            value: query.get("coverageType")!,
            label: query.get("coverageType")!,
          }
        : null;
      setFieldValue("eligibility.gender", query.get("gender"));
      setFieldValue("eligibility.firstName", query.get("firstName"));
      setFieldValue("eligibility.lastName", query.get("lastName"));
      setFieldValue("eligibility.month", month);
      setFieldValue("eligibility.day", day);
      setFieldValue("eligibility.year", year);
      setFieldValue("eligibility.zip", query.get("zip"));
      setFieldValue("eligibility.email", query.get("email"));
      setFieldValue("eligibility.isDependent", query.get("relationship"));
      handleCoverageTypeChange(JWTCoverageType);
      setFieldValue("eligibility.subscriberId", query.get("subscriberId"));
    } else if (
      member?.eligibilities[0]?.currentSubscriptionStatus === "Cancelled" &&
      !member?.eligibilities[0]?.isPrimary
    ) {
      const { firstName, lastName, dateOfBirth, email, postalCode } = member;

      setFieldValue("eligibility.firstName", firstName);
      setFieldValue("eligibility.lastName", lastName);
      setFieldValue("eligibility.month", dateOfBirth.substring(5, 7));
      setFieldValue("eligibility.day", dateOfBirth.substring(8, 10));
      setFieldValue("eligibility.year", dateOfBirth.substring(0, 4));
      setFieldValue("eligibility.email", email);
      setFieldValue("eligibility.zip", postalCode);
      setIsDependentToPrimary(true);
    }
  }, [user.isSSO, isImpersonated]);

  useEffect(() => {
    if (values.eligibility?.completed && next) {
      next();
    }
  }, []);

  useEffect(() => {
    if (status && intervalId) {
      if (progress >= 100) {
        setProgress(100);
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
    () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [progress, status]);

  const getAge = (dateString: string) => {
    const today = new Date(),
      birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const isOverMinBuyerAge = () => {
    if (values.eligibility && values.eligibility.year) {
      const age = getAge(
        `${values.eligibility?.month}/${values.eligibility?.day}/${values.eligibility?.year}`
      );
      if (!isNaN(age) && age >= clientConfig.minBuyerMemberAge!) return true;
      return false;
    }
  };

  const handleCoverageTypeChange = (
    selectedCoverageType: CoverageTypeOption | null
  ) => {
    setCoverageTypeSelected(selectedCoverageType);
    setIsCoverageTypeValid(true);
    setFieldValue("eligibility.coverageType", selectedCoverageType!.value);
  };

  const handleTransition = () => {
    setTimeout(() => {
      setLoadingFields(false);
    }, 2000);
  };

  const handleCoverageTypeBlur = () => {
    if (coverageTypeSelected === null && isCoverageTypeValid === false) {
      return;
    }
    if (coverageTypeSelected === null && isCoverageTypeValid === true) {
      setIsCoverageTypeValid(false);
    }
    if (coverageTypeSelected !== null && isCoverageTypeValid === true) {
      return;
    }
    if (coverageTypeSelected !== null && isCoverageTypeValid === false) {
      setIsCoverageTypeValid(true);
    }
  };

  const handleCheckEligibility = async () => {
    setProgress(0);
    setIntervalId(setInterval(() => setProgress((p) => p + 5), 700));
    const vals = values.eligibility!;
    //TODO: might need to do some logic for dependent to primary enrollments (may not be needed for FYW)
    // const eligibilityEndpoint = isDependentToPrimary
    //   ? "/client-eligibility"
    //   : "/eligibility";
    const eligibilityEndpoint = "/eligibility";
    setStatus("loading");
    setMessage("We're confirming your information");
    axiosInstance
      .post(eligibilityEndpoint, {
        gender: vals.gender,
        firstName: vals.firstName,
        lastName: vals.lastName,
        dateOfBirth: `${vals.year}-${vals.month}-${vals.day}`,
        email: vals.email,
        zip: vals.zip,
        subscriberId: vals.subscriberId,
        isDependent: false,
        createLogin: true,
        clientSpecificData: {
          clientCode: isBSCA ? "bsca" : "bcbsa",
          ...(isBSCA && { groupNumber: vals.groupNumber }),
          ...(!isBSCA && {
            isPlanPrimary: isDependentToPrimary ? false : true,
          }),
          ...(!isBSCA && { coverageType: vals.coverageType }),
        },
      })
      .then((response) => {
        switch (response.data?.message) {
          case ELIGIBILITY_MESSAGE.Eligible:
            setFieldValue("eligibility.completed", true);
            setFieldValue("eligibility.consumerId", response.data.consumerId);
            setFieldValue(
              "eligibility.clientMemberId",
              isBSCA
                ? values.eligibility?.subscriberId
                : response.data.clientMemberId
            );
            setFieldValue(
              "eligibility.clientCode",
              response.data.clientSpecificData.clientCode
            );
            setFieldValue("eligibility.isPayroll", false);
            setClientCode(response.data.clientSpecificData.clientCode);
            setFieldValue(
              "eligibility.planId",
              isBSCA ? "BSC" : response.data.clientSpecificData?.planId
            );
            setFieldValue(
              "eligibility.groupId",
              isBSCA
                ? values.eligibility?.groupNumber
                : response.data.clientSpecificData?.planId
            );
            setUser({
              status: USER_STATUS.ELIGIBLE,
              isPayroll:
                response.data.payrollStatus === USER_PAYROLL_STATUS.Payroll,
            });
            if (response.data?.clientSpecificData.isFitnessWorksEligible) {
              setStatus("");
              setShowRegistrationModel(true);
            } else {
              setStatus("CONFIRMED");
            }
            break;
          //Partial Eligible works the same as Eligible except it is only for partial eligible BCBSA members only
          case ELIGIBILITY_MESSAGE.PartialEligible:
            setFieldValue("eligibility.completed", true);
            setFieldValue("eligibility.consumerId", response.data.consumerId);
            setFieldValue(
              "eligibility.clientMemberId",
              response.data.clientMemberId
            );
            setFieldValue(
              "eligibility.clientCode",
              response.data.clientSpecificData.clientCode
            );
            setFieldValue("eligibility.isPayroll", false);
            setClientCode(response.data.clientSpecificData.clientCode);
            setFieldValue(
              "eligibility.planId",
              response.data.clientSpecificData?.planId
            );
            setFieldValue(
              "eligibility.groupId",
              response.data.clientSpecificData?.planId
            );
            setUser({
              status: USER_STATUS.ELIGIBLE,
              isPayroll:
                response.data.payrollStatus === USER_PAYROLL_STATUS.Payroll,
            });
            if (response.data?.clientSpecificData.isFitnessWorksEligible) {
              setStatus("");
              setShowRegistrationModel(true);
            } else {
              setStatus("CONFIRMED");
            }
            break;
          case ELIGIBILITY_MESSAGE.NotEligible:
            setStatus("DENIED");
            if (response.data.clientSpecificData.partialEligibilityErrorCode) {
              switch (
                response.data.clientSpecificData.partialEligibilityErrorCode
              ) {
                case PARTIAL_ELIGIBILITY_ERROR_CODE.E009:
                  setMessage(
                    "Oops. Something went wrong and we were unable to verify your eligibility. Please try again later. You may also contact Blue365 at: support@Blue365Deals.com or call 1 (855) 511-BLUE (2583) during business hours. Error Code: E009"
                  );
                  break;

                case PARTIAL_ELIGIBILITY_ERROR_CODE.E010:
                  setMessage(
                    "Oops. Something went wrong and we were unable to verify your eligibility. Please ensure that your information, including your complete subscriber ID, is correct and try again. You may also contact Blue365 at: support@Blue365Deals.com or call 1 (855) 511-BLUE (2583) during business hours. Error Code: E010"
                  );
                  break;
                case PARTIAL_ELIGIBILITY_ERROR_CODE.E011:
                  setMessage(
                    "Oops. Something went wrong and we were unable to verify your eligibility. You may also contact Blue365 at: support@Blue365Deals.com or call 1 (855) 511-BLUE (2583) during business hours. Error Code: E011"
                  );
                  break;
                case PARTIAL_ELIGIBILITY_ERROR_CODE.E014:
                  setMessage(
                    "Sorry, but it appears that your dental or vision only coverage could not be verified. Please contact Blue365 at support@blue365Deals.com or call 1 (855) 511-BLUE (2583). Error Code: E014"
                  );
                  break;

                default:
                  setMessage(
                    `The information you provided could not be confirmed as eligible. Please review your information, and try again. Error Code: ${response.data.clientSpecificData.partialEligibilityErrorCode}`
                  );
                  break;
              }
            } else {
              setMessage(
                "The information you provided could not be confirmed as eligible. Please review your information, and try again."
              );
            }

            break;
          case ELIGIBILITY_MESSAGE.EmailUnavailable:
            setStatus("NOEMAIL");
            setMessage(
              "The email address you entered is already in use. Please use a different email address for your account creation."
            );
            break;
          case ELIGIBILITY_MESSAGE.LoginCreated:
            setStatus(ELIGIBILITY_MESSAGE.LoginCreated);
            // setMessage(
            //   "Your Account has been created, please check your email to complete account creation."
            // );
            break;
          case ELIGIBILITY_MESSAGE.IsSSO:
            setStatus(ELIGIBILITY_MESSAGE.IsSSO);
            break;
          case ELIGIBILITY_MESSAGE.ExistingLoginFound:
            setStatus("EXISTS");
            setMessage(
              "Our Records indicate that you already have an account with us. Please login or reset your password to access your account."
            );
            break;
        }
      })
      .catch((error) => {
        console.log(error.toJSON());
        setMessage(
          "The information you provided could not be confirmed as eligible. Please review your information, and try again."
        );
        setStatus("DENIED");
      });
  };

  const handleHandoff = () => {
    if (next) {
      next();
    }
  };

  const onFwError = () => {
    setShowRegistrationModel(false);
    setStatus("ERROR-CHECKOUT");
  };

  const scrollTo = (elementName) => {
    const el = document.getElementsByName(elementName);
    el[0].scrollIntoView();
  };

  const handlePrivacyPolicyKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      setFieldValue(
        "eligibility.privacyPolicy",
        !values.eligibility!.privacyPolicy
      );
    }
  };
  const handleBcbsaAgreementKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      setFieldValue(
        "eligibility.bcbsaAgreement",
        !values.eligibility!.bcbasAgreement
      );
    }
  };

  const handleCoverageTypeKeyDown: React.KeyboardEventHandler<HTMLElement> = (
    event
  ) => {
    if (event.key === "Enter" && selectRef.current) {
      selectRef.current.select.openMenu("first");
    }
  };

  const isErrorFound = () => {
    const eligibility = values.eligibility as {
      month?: string;
      day?: string;
      year?: string;
      privacyPolicy?: boolean;
      bcbsaAgreement?: boolean;
      isDependent?: boolean;
    };

    return (
      Boolean(errors.eligibility !== undefined) ||
      (eligibility.month &&
        typeof eligibility.month === "string" &&
        eligibility.month.length < 2) ||
      (eligibility.day &&
        typeof eligibility.day === "string" &&
        eligibility.day.length < 2) ||
      (eligibility.year &&
        typeof eligibility.year === "string" &&
        eligibility.year.length < 4) ||
      !isOverMinBuyerAge() ||
      eligibility.privacyPolicy !== true ||
      (!isBSCA && eligibility.bcbsaAgreement !== true) ||
      (!isBSCA && eligibility.isDependent === undefined)
    );
  };

  useEffect(() => {
    setFieldValue("eligibility.email", email);
  }, [email, setFieldValue]);

  return (
    <div className="eligibility-form-container">
      <StatusModal
        show={status !== ""}
        status={status}
        message={message}
        onCancel={() => setStatus("")}
        onNext={handleHandoff}
        progress={progress}
        clientCode={clientCode}
      />
      {user.isSSO && showJWTSSOModal && (
        <StatusModal
          show={showJWTSSOModal}
          status={ELIGIBILITY_MESSAGE.JWTSSO}
          message="You are already logged in. Please log out to continue."
          onCancel={() => setShowJWTSSOModal(false)}
          onNext={() => setShowJWTSSOModal(false)}
          email={email}
          setEmail={setEmail}
          handleCheckEligibility={handleCheckEligibility}
        />
      )}
      {showRegistrationModel && <FwEligibilityModal onFail={onFwError} />}
      <Header
        title={
          user.isSSO ? `Confirm Your Information` : `Check Your Eligibility`
        }
        subtitle={
          !user.isSSO
            ? `Please fill out the information below to see if you're eligible`
            : ""
        }
      />
      <Card className="eligibility-form" style={{ marginTop: 20 }}>
        <Card.Body style={{ padding: "24px" }}>
          <Card.Title
            style={{ color: "#000000", fontSize: "16px", fontWeight: "bold" }}
          >
            PERSONAL INFO
          </Card.Title>
          <p
            style={{ marginBottom: "15px", fontSize: "14px", color: "#333333" }}
          >
            We will use the info below to authenticate your account
          </p>
          <Row className="eligibility-form__row">
            <Form.Group as={Col} xs={12}>
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                What is your gender?{" "}
                <span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Group
                as={Col}
                xs={12}
                className="mb-3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "8px",
                }}
              >
                <Form.Check
                  inline
                  type="radio"
                  name="eligibility.gender"
                  label="Male"
                  value="M"
                  isInvalid={
                    getIn(touched, "eligibility.gender") &&
                    getIn(errors, "eligibility.gender")
                  }
                  checked={values?.eligibility?.gender === "M"}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                  onChange={(e) => {
                    setFieldValue("eligibility.gender", e.target.value);
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "0",
                    marginLeft: "-.5rem",
                    color: "#11435A",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                  disabled={user.isSSO}
                />
                <Form.Check
                  inline
                  type="radio"
                  name="eligibility.gender"
                  label="Female"
                  value="F"
                  isInvalid={
                    getIn(touched, "eligibility.gender") &&
                    getIn(errors, "eligibility.gender")
                  }
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                  checked={values?.eligibility?.gender === "F"}
                  onChange={(e) => {
                    setFieldValue("eligibility.gender", e.target.value);
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#11435A",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                  disabled={user.isSSO}
                />
              </Form.Group>
            </Form.Group>
          </Row>
          <Row className="eligibility-form__row">
            <Form.Group
              as={Col}
              controlId="firstName"
              xs={12}
              md={4}
              lg={3}
              className="mb-3"
            >
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                First Name<span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                className="eligibility-placeholder"
                type="text"
                required
                maxLength={35}
                name="eligibility.firstName"
                value={values.eligibility!.firstName as string}
                placeholder="Enter first name"
                disabled={user.isSSO || impersonateLockForm}
                isInvalid={
                  getIn(touched, "eligibility.firstName") &&
                  getIn(errors, "eligibility.firstName")
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "eligibility.firstName")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="lastName" xs={12} md={4} lg={3}>
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                Last Name<span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                className="eligibility-placeholder"
                type="text"
                required
                maxLength={60}
                name="eligibility.lastName"
                value={values.eligibility!.lastName as string}
                placeholder="Enter last name"
                disabled={user.isSSO || impersonateLockForm}
                isInvalid={
                  getIn(touched, "eligibility.lastName") &&
                  getIn(errors, "eligibility.lastName")
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "eligibility.lastName")}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Label className="form-control-sm col-form-label p-0 eligibility-form__label">
            Date of Birth <span className="required-field-marker">*</span>
          </Form.Label>
          <Row className="eligibility-form__row">
            <Form.Group
              as={Col}
              controlId="month"
              xs={6}
              sm={3}
              md={2}
              xl={1}
              className="mb-3"
            >
              <Form.Label
                style={{ color: "#757575", fontSize: "12px" }}
                className="form-control-sm col-form-label p-0"
              >
                Month
              </Form.Label>
              <Form.Control
                className="eligibility-placeholder"
                type="text"
                name="eligibility.month"
                placeholder="MM"
                value={values.eligibility!.month}
                disabled={user.isSSO || impersonateLockForm}
                isInvalid={
                  (getIn(touched, "eligibility.month") &&
                    getIn(errors, "eligibility.month")) ||
                  (getIn(touched, "eligibility.month") &&
                    (values.eligibility?.month as string).length !== 2) ||
                  (getIn(touched, "eligibility.month") &&
                    isNaN(Number(values.eligibility?.month)))
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="day" xs={6} sm={3} md={2} xl={1}>
              <Form.Label
                style={{ color: "#757575", fontSize: "12px" }}
                className="form-control-sm col-form-label p-0"
              >
                Day
              </Form.Label>
              <Form.Control
                className="eligibility-placeholder"
                type="text"
                name="eligibility.day"
                placeholder="DD"
                value={values.eligibility!.day}
                disabled={user.isSSO || impersonateLockForm}
                isInvalid={
                  (getIn(touched, "eligibility.day") &&
                    getIn(errors, "eligibility.day")) ||
                  (getIn(touched, "eligibility.day") &&
                    (values.eligibility?.day as string).length !== 2) ||
                  (getIn(touched, "eligibility.day") &&
                    isNaN(Number(values.eligibility?.day)))
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="year" xs={6} sm={3} md={2} xl={1}>
              <Form.Label
                style={{ color: "#757575", fontSize: "12px" }}
                className="form-control-sm col-form-label p-0 pb-0"
              >
                Year
              </Form.Label>
              <Form.Control
                className="eligibility-placeholder"
                type="text"
                name="eligibility.year"
                value={values.eligibility!.year}
                disabled={user.isSSO || impersonateLockForm}
                isInvalid={
                  (getIn(touched, "eligibility.year") &&
                    getIn(errors, "eligibility.year")) ||
                  (getIn(touched, "eligibility.year") &&
                    (values.eligibility?.year as string).length !== 4) ||
                  (getIn(touched, "eligibility.year") &&
                    isNaN(Number(values.eligibility?.year))) ||
                  (getIn(touched, "eligibility.year") && !isOverMinBuyerAge())
                }
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="YYYY"
              />
            </Form.Group>
          </Row>
          {getIn(touched, "eligibility.year") && !isOverMinBuyerAge() && (
            <div className="invalid-feedback-dob">
              Must be{" "}
              {clientConfig?.minBuyerMemberAge
                ? clientConfig.minBuyerMemberAge.toString()
                : "18"}{" "}
              years or older
            </div>
          )}
          <Row className="eligibility-form__row">
            <Form.Group as={Col} xs={9} sm={5} md={3} className="mb-3">
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                ZIP Code<span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                className="eligibility-placeholder"
                type="text"
                name="eligibility.zip"
                value={values.eligibility!.zip}
                placeholder="Enter zip code"
                isInvalid={
                  getIn(touched, "eligibility.zip") &&
                  getIn(errors, "eligibility.zip")
                }
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={user.isSSO}
              />
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "eligibility.zip")}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="eligibility-form__row">
            <Form.Group as={Col} xs={12} sm={9} md={5}>
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                {values.eligibility!.hasBlue365 === "true"
                  ? "Blue365 Email Address"
                  : "Email Address"}
                <span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                className="eligibility-placeholder"
                type="text"
                name="eligibility.email"
                value={values.eligibility!.email as string}
                placeholder="Enter email address"
                isInvalid={
                  getIn(touched, "eligibility.email") &&
                  getIn(errors, "eligibility.email")
                }
                onChange={(e) => {
                  handleChange;
                  setEmail(e.target.value);
                }}
                onBlur={handleBlur}
                onFocus={() => scrollTo("eligibility.day")}
              />
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "eligibility.email")}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="eligibility-form__row">
            <Form.Group as={Col} xs={12} sm={9} md={2} lg={6} className="mb-3">
              <Alert className="blue365-banner">
                <Alert.Heading>
                  <span className="blue365-banner blue365-icon">
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </span>
                  <b className="blue365-banner blue365-title">
                    {" "}
                    if you have a Blue365 account, use the email address
                    associated with that account
                  </b>
                </Alert.Heading>
              </Alert>
            </Form.Group>
          </Row>
          <Card.Title
            style={{
              color: "#000000",
              marginTop: "20px",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            HEALTH PLAN INFO
          </Card.Title>
          <p style={{ fontSize: "14px", color: "#333333" }}>
            Please enter your health plan info below to confirm eligibility
          </p>
          <br />
          {loadingFields ? (
            <>
              <Row
                className="eligibility-form__row"
                style={{
                  backgroundColor: "#EAEAEA",
                  width: "604px",
                  height: "153px",
                }}
              >
                <div className="d-flex justify-content-center">
                  <Spinner
                    className="loading-fields-spinner m-4"
                    animation="border"
                    variant="dark"
                    style={{ width: "62px", height: "62px", fontSize: "30px" }}
                  />
                </div>
                <div
                  className="d-flex justify-content-center"
                  style={{ marginTop: "-40px", color: "#11435A" }}
                >
                  Loading Fields for {!isBSCA ? "BCBSA" : "BSC"} members...
                </div>
              </Row>
            </>
          ) : (
            <>
              <Row className="eligibility-form__row">
                <Form.Group as={Col} xs={12}>
                  <Form.Label
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#757575",
                    }}
                    className="form-control-sm col-form-label p-0 pb-1 add-family-member-form__label"
                  >
                    Are you a member of Blue Shield California? (BSC){" "}
                    <span className="required-field-marker">*</span>
                  </Form.Label>
                  <Form.Group
                    as={Col}
                    xs={12}
                    className="mb-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "8px",
                    }}
                  >
                    <Form.Check
                      inline
                      type="radio"
                      name="eligibility.isBSCA"
                      value="true"
                      label="Yes"
                      checked={isBSCA === true}
                      isInvalid={
                        getIn(touched, "eligibility.isBSCA") &&
                        getIn(errors, "eligibility.isBSCA")
                      }
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      onChange={() => {
                        setIsBSCA(true);
                        setFieldValue("eligibility.isBSCA", true);
                        setClient("bsca");
                        setLoadingFields(true);
                        handleTransition();
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "0",
                        marginLeft: "-.5rem",
                        color: "#11435A",
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                      disabled={user.isSSO}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      name="eligibility.isBSCA"
                      label="No"
                      value="false"
                      checked={isBSCA === false}
                      isInvalid={
                        getIn(touched, "eligibility.isBSCA") &&
                        getIn(errors, "eligibility.isBSCA")
                      }
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      onChange={() => {
                        setIsBSCA(false);
                        setFieldValue("eligibility.isBSCA", false);
                        setClient("bcbsa");
                        setLoadingFields(true);
                        handleTransition();
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#11435A",
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                      disabled={user.isSSO}
                    />
                  </Form.Group>
                </Form.Group>
              </Row>
              {!isBSCA && (
                <Row className="eligibility-form__row">
                  <Form.Group as={Col} xs={12}>
                    <Form.Label
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#757575",
                      }}
                      className="form-control-sm col-form-label p-0 pb-1 add-family-member-form__label"
                    >
                      Are you the primary member for your health plan or a
                      dependent?{" "}
                      <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Group
                      as={Col}
                      xs={12}
                      className="mb-3"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "8px",
                      }}
                    >
                      <Form.Check
                        inline
                        type="radio"
                        name="eligibility.isDependent"
                        label="Primary"
                        value="primary"
                        checked={
                          isDependentToPrimary === false ||
                          values?.eligibility?.isDependent === "primary"
                        }
                        isInvalid={
                          getIn(touched, "eligibility.isDependent") &&
                          getIn(errors, "eligibility.isDependent")
                        }
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        onChange={() => {
                          setIsDependentToPrimary(false);
                          setFieldValue("eligibility.isDependent", false);
                        }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "0",
                          marginLeft: "-.5rem",
                          color: "#11435A",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                        disabled={user.isSSO}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        name="eligibility.isDependent"
                        label="Dependent"
                        value="dependent"
                        checked={
                          isDependentToPrimary === true ||
                          values?.eligibility?.isDependent === "dependent"
                        }
                        isInvalid={
                          getIn(touched, "eligibility.isDependent") &&
                          getIn(errors, "eligibility.isDependent")
                        }
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        onChange={() => {
                          setIsDependentToPrimary(true);
                          setFieldValue("eligibility.isDependent", true);
                        }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: "#11435A",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                        disabled={user.isSSO}
                      />
                    </Form.Group>
                  </Form.Group>
                </Row>
              )}
              <Row className="eligibility-form__row">
                <>
                  {!isBSCA && (
                    <Form.Group
                      as={Col}
                      xs={12}
                      sm={9}
                      md={2}
                      lg={2}
                      className="mb-3"
                    >
                      <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                        Coverage Type
                        <span className="required-field-marker">*</span>
                      </Form.Label>
                      <Select
                        classNamePrefix="custom-select"
                        name="eligibility.coverageType"
                        placeholder={
                          <div style={{ color: "#b6b6b6", fontSize: "12px" }}>
                            Choose coverage type
                          </div>
                        }
                        value={coverageTypeSelected}
                        options={coverageTypeOptions}
                        isInvalid={!isCoverageTypeValid}
                        onChange={handleCoverageTypeChange}
                        onBlur={handleCoverageTypeBlur}
                        styles={customStyles}
                        isDisabled={user.isSSO}
                        onKeyDown={handleCoverageTypeKeyDown}
                        menuPortalTarget={document.body}
                        ref={selectRef}
                      />
                      {!isCoverageTypeValid && (
                        <div className="invalid-select">
                          Coverage Type is required.
                        </div>
                      )}
                    </Form.Group>
                  )}
                  <Form.Group
                    as={Col}
                    xs={12}
                    sm={9}
                    md={5}
                    lg={3}
                    className="mb-3"
                  >
                    <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                      Subscriber ID
                      <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                      className="eligibility-placeholder"
                      type="text"
                      name="eligibility.subscriberId"
                      placeholder="Enter subscriber ID"
                      value={values.eligibility!.subscriberId as string}
                      isInvalid={
                        getIn(touched, "eligibility.subscriberId") &&
                        getIn(errors, "eligibility.subscriberId")
                      }
                      disabled={user.isSSO}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {getIn(errors, "eligibility.subscriberId")}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {isBSCA && (
                    <Form.Group as={Col} xs={12} sm={9} md={3} className="mb-3">
                      <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                        Group #<span className="required-field-marker">*</span>
                      </Form.Label>
                      <Form.Control
                        className="eligibility-placeholder"
                        placeholder="Enter group number"
                        type="text"
                        name="eligibility.groupNumber"
                        value={values.eligibility!.groupNumber as string}
                        isInvalid={
                          getIn(touched, "eligibility.groupNumber") &&
                          getIn(errors, "eligibility.groupNumber")
                        }
                        disabled={user.isSSO}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={() => scrollTo("eligibility.groupNumber")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {getIn(errors, "eligibility.groupNumber")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                </>
              </Row>

              <Row className="eligibility-form__row">
                <Form.Group
                  className="mb-3"
                  controlId="formBasicCheckboxPrivacyPolicy"
                >
                  <Row className="eligibility-form__row">
                    <Col
                      xs={1}
                      style={{
                        width: "20px",
                        paddingTop: "4px",
                        marginRight: "0",
                      }}
                    >
                      <Form.Check
                        id="privacy"
                        value="true"
                        isInvalid={
                          getIn(touched, "eligibility.privacyPolicy") &&
                          getIn(errors, "eligibility.privacyPolicy")
                        }
                        name="eligibility.privacyPolicy"
                        checked={values.eligibility!.privacyPolicy === true}
                        inline
                        style={{
                          paddingLeft: 0,
                        }}
                        onChange={handleChange}
                        onKeyDown={handlePrivacyPolicyKeyDown}
                      />
                    </Col>
                    <Col xs={9} sm={8} style={{ padding: 0, margin: 0 }}>
                      <span style={{ fontSize: "12px", color: "#757575" }}>
                        <span className="required-field-marker">*</span> By
                        providing your email address and/or any other personal
                        information, as defined under applicable law, you
                        acknowledge that you are agreeing to our use of your
                        information as provided in our{" "}
                        <a
                          href="/terms"
                          target="_blank"
                          rel="noreferrer"
                          className="a-link"
                        >
                          Terms of Use
                        </a>{" "}
                        and{" "}
                        <a
                          href="/privacy-policy"
                          target="_blank"
                          rel="noreferrer"
                          className="a-link"
                        >
                          Privacy Policy
                        </a>
                      </span>
                    </Col>
                  </Row>
                  <Row className="eligibility-form__row">
                    <Form.Control.Feedback type="invalid">
                      {getIn(errors, "eligibility.privacyPolicy")}
                    </Form.Control.Feedback>
                  </Row>
                </Form.Group>
              </Row>
              {!isBSCA && (
                <Row className="eligibility-form__row">
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicCheckboxBcbsaAgreement"
                  >
                    <Row className="eligibility-form__row">
                      <Col
                        xs={1}
                        style={{
                          width: "20px",
                          paddingTop: "4px",
                          marginRight: "0",
                        }}
                      >
                        <Form.Check
                          id="bcbsaAgreement"
                          value="true"
                          isInvalid={
                            getIn(touched, "eligibility.bcbsaAgreement") &&
                            getIn(errors, "eligibility.bcbsaAgreement")
                          }
                          name="eligibility.bcbsaAgreement"
                          checked={values.eligibility!.bcbsaAgreement === true}
                          inline
                          style={{ paddingLeft: 0 }}
                          onChange={handleChange}
                          onKeyDown={handleBcbsaAgreementKeyDown}
                        />
                      </Col>
                      <Col xs={9} sm={8} style={{ padding: 0, margin: 0 }}>
                        <span style={{ fontSize: "12px", color: "#757575" }}>
                          <span className="required-field-marker">*</span> I
                          agree to share my contact information with Blue Cross
                          and Blue Shield Association
                        </span>
                      </Col>
                    </Row>
                    <Row className="eligibility-form__row">
                      <Form.Control.Feedback type="invalid">
                        {getIn(errors, "eligibility.bcbsaAgreement")}
                      </Form.Control.Feedback>
                    </Row>
                  </Form.Group>
                </Row>
              )}
            </>
          )}
        </Card.Body>
      </Card>
      <FooterCard>
        <Container>
          <Row>
            <Col sm={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 5 }}></Col>
            <Col sm={5} lg={3}>
              <>
                <Button
                  variant="primary"
                  className="nav-btn-enroll font-weight-bold"
                  style={{ whiteSpace: "nowrap", width: "100%" }}
                  onClick={handleCheckEligibility}
                  disabled={
                    user.isSSO
                      ? isErrorFound()
                      : Object.keys(touched).length === 0
                      ? true
                      : isErrorFound()
                  }
                >
                  {user.isSSO ? "Confirm Information" : "Check My Eligibility"}
                </Button>
              </>
            </Col>
          </Row>
        </Container>
      </FooterCard>
    </div>
  );
};

export default Eligibility;
