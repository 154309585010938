import { useState, useEffect, useCallback } from "react";
import { useConfig } from "../configuration/useConfig";
import axiosInstance from "../axios.instance";
import useIncontact from "./useIncontact";

export type PaymentMethodTypes = {
  pageId: string;
  pageType: string;
};

export type ZuoraProps = Record<string, string | number | boolean | null>;

export interface IZuoraResponse {
  success: boolean;
  refId: string;
  responseFrom: string;
}

interface IZuoraHook {
  render: () => void;
  handleSubmit: (e: unknown) => void;
  hasRendered: boolean;
  zProps: ZuoraProps;
}
interface PrePopulate {
  creditCardPostalCode: string;
  achPostalCode: string;
  creditCardHolderName: string;
}
const useZuora = ({
  paymentType,
  prePopulate,
  onSuccess,
  onSubmissionFailed,
  onRequestFailed,
}: {
  paymentType: string;
  prePopulate?: PrePopulate;
  onSuccess: (res: unknown) => unknown;
  onSubmissionFailed: (res: unknown) => unknown;
  onRequestFailed: (res: unknown) => unknown;
}): IZuoraHook => {
  const { isImpersonated, setMaskDisabled } = useIncontact();
  const [props, setProps] = useState<ZuoraProps>({
    tenantId: "",
    id: "",
    token: null,
    signature: null,
    style: "inline",
    key: null,
    submitEnabled: true,
    locale: "English (en)",
    param_supportedTypes: "AmericanExpress,Visa,MasterCard,Discover",
  });
  const [toRender, setToRender] = useState(false);
  const [loading, setLoading] = useState(true);
  const [prepop] = useState(prePopulate ? prePopulate : {});
  const [rendered, setRendered] = useState(false);
  const [paymentTypesLoading, setPaymentTypesLoading] = useState(true);
  const [readyToSend, setReadyToSend] = useState(false);

  const [paymentMethodTypes, setPaymentMethodTypes] = useState<
    PaymentMethodTypes[] | null
  >(null);

  const { config } = useConfig();

  useEffect(() => {
    setToRender(false);
    setRendered(false);
    setLoading(true);
    setReadyToSend(false);
  }, [paymentType]);

  useEffect(() => {
    setTimeout(() => {
      getPaymentTypes();
    }, 2000);
  }, []);

  const getCurrentPageId = (paymentType: string) => {
    return paymentMethodTypes!.filter((p) => p.pageType === paymentType)[0]?.pageId;
  };

  useEffect(() => {
    if (paymentMethodTypes !== null) {
      if (window.location.href.includes(":3005")) {
        if (config["client"] === "walmart") {
          setCCPayment("2c92c0f87506696b01750aae5fb94669");
        } 
        if (config["client"] === "hcsc") {
          setCCPayment("2c92c0f9699ec9f80169b6fca486655b");
        } 
        if (config["client"] === "bcbsa") {
          setCCPayment("8ad08c0f7c642e21017c76e994182500");
        }
        if (config["client"] === "bsca") {
          setCCPayment("8ad084a67c642e25017c76f5fce13d15");
        }
        if (config["client"] === "mutual-of-omaha") {
          setCCPayment("8ad081dd91f0ebba0192013fe38f580d");
        }
      } else {
        const pageId = getCurrentPageId(paymentType);
        setCCPayment(pageId);
      }
    }
  }, [paymentTypesLoading, paymentType]);

  const getPaymentTypes = async () => {
    setPaymentTypesLoading(true);
    await axiosInstance
      .get(`/payment-method-types`)
      .then((response) => {
        if (response.data) {
          setPaymentMethodTypes(response.data);
          setPaymentTypesLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.toJSON());
        setPaymentTypesLoading(false);
      });
  };

  const setCCPayment = async (pageId: string) => {
    setLoading(true);
    await axiosInstance
      .get(`/payment-method-types/${pageId}`)
      .then((response) => {
        if (response.data) {
          setProps((p) => ({
            ...p,
            ...{
              tenantId: response.data.tenantId,
              paymentGateway: response.data.paymentGateway,
              token: response.data.token,
              id: response.data.id,
              signature: response.data.signature,
              key: response.data.key,
              url: response.data.url,
            },
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setReadyToSend(true);
    setLoading(false);
  };

  const callback = useCallback(
    (response: IZuoraResponse) => {
      if (response.success) {
        onSuccess(response);
      } else {
        if (response.responseFrom === "Response_From_Submit_Page") {
          onSubmissionFailed(response);
        } else {
          onRequestFailed(response);
        }
      }
    },
    [onSuccess, onSubmissionFailed, onRequestFailed]
  );

  //render when we can.
  useEffect(() => {
    if (toRender && !loading && !rendered && readyToSend) {
      window.Z.render(props, prepop, callback);
      setRendered(true);
    }
  }, [toRender, loading, props, prepop, callback, rendered]);
  //
  const render = useCallback(() => {
    setToRender(true);
  }, []);

  const handleSubmit = useCallback((e) => {
    if (isImpersonated) setMaskDisabled();
    e.preventDefault();
    window.Z.submit();
  }, []);

  return {
    render,
    handleSubmit,
    hasRendered: rendered,
    zProps: props,
  };
};

export default useZuora;
