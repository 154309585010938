import React, { Fragment, useEffect, useState } from "react";
import { useCallback } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import useSmartyStreets from "../../../hooks/useSmartyStreets";
import { SmartyAddress } from "../types";
import { AccountAddress } from "../../../utility/member-portal-api-types";

const states = [
  "",
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
];

interface AddressFormProps {
  onChangeSmarty: (t: SmartyAddress[]) => void;
  onInputChange: (
    input: string,
    e: unknown | React.ChangeEvent<unknown>
  ) => void;
  onChange: (e: React.ChangeEvent<unknown>) => void;
  values:
    | AccountAddress
    | {
        address1: string;
        address2: string;
        city: string;
        state: string;
        postalCode: string;
      };
  prefix: string;
}
/*
 * Handles async typeahead component/ rest of form
 *
 * holds state for address that typeahead component uses
 * calls handlers to update formik state in parent component
 *
 */

const AddressForm = ({
  onChangeSmarty,
  onChange,
  onInputChange,
  values,
  prefix,
}: AddressFormProps) => {
  const [address, setAddress] = useState<SmartyAddress[]>([]);
  const { lookup, results, loading } = useSmartyStreets();

  //useCallback required due to bre"ak"k"in"g" c"h"an"g"es"
  //https://github.com/ericgio/react-bootstrap-typeahead/issues/561
  const handleSuggestions = useCallback(
    (query: string) => {
      lookup(query);
    },
    [lookup]
  );
  //formats the address for smarty streets on value update
  useEffect(() => {
    setAddress([
      {
        label: values.address1,
        address: {
          city: values.city,
          state: values.state,
          zipcode: values.postalCode,
          secondary: values.address2 ?? "",
          streetLine: values.address1,
          entries: 1,
          //   address1: values.street1,
          //   address2: values.street2,
          //   zipCode: values.postalCode,
        },
      },
    ]);
  }, [values]);

  return (
    <Fragment>
      <Row>
        <Form.Group as={Col} sm={12} className="mb-3 p-0">
          <Form.Label
            className="eligibility-form__label edit-profile__title"
            column
            sm={12}
          >
            ADDRESS<i className="m-0 text-danger">*</i>
          </Form.Label>
          <AsyncTypeahead
            id="address"
            isLoading={loading}
            selected={address}
            onSearch={handleSuggestions}
            onChange={onChangeSmarty}
            onInputChange={onInputChange}
            renderMenuItemChildren={(option) => {
              return `${option.address.streetLine} ${option.address.city}, ${option.address.state} ${option.address.zipcode}`;
            }}
            options={results.map((r) => ({
              label: `${r.streetLine}`,
              address: r,
            }))}
            className="edit-profile__input2"
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} sm={12} className="mb-3 p-0">
          <Form.Label className="eligibility-form__label edit-profile__title">
            APT, UNIT, ETC (OPTIONAL)
          </Form.Label>
          <Form.Control
            type="text"
            name={prefix !== "" ? prefix + ".address2" : "address2"}
            value={values.address2}
            onChange={onChange}
            className="edit-profile__input2"
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} xs={6} md={5} className="mb-3 p-0">
          <Form.Label className="eligibility-form__label edit-profile__title">
            CITY<i className="m-0 text-danger">*</i>
          </Form.Label>
          <Form.Control
            type="text"
            name={prefix !== "" ? prefix + ".city" : "city"}
            value={values.city}
            onChange={onChange}
            className="edit-profile__input2"
          />
        </Form.Group>
        <Form.Group as={Col} xs={3} className="mb-3 p-0 mx-3">
          <Form.Label className="eligibility-form__label edit-profile__title">
            STATE<i className="m-0 text-danger">*</i>
          </Form.Label>
          <Form.Control
            as="select"
            className="form-control edit-profile__input2 form-select"
            name={prefix !== "" ? prefix + ".state" : "state"}
            value={values.state.toUpperCase()}
            onChange={onChange}
          >
            {states.map((state, index) => {
              return (
                <option key={`address-${index}`} value={state}>
                  {state}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} xs={5} lg={3} className="mb-3 p-0">
          <Form.Label className="eligibility-form__label edit-profile__title">
            ZIP CODE<i className="m-0 text-danger">*</i>
          </Form.Label>
          <Form.Control
            type="text"
            name={prefix !== "" ? prefix + ".zip" : "zip"}
            value={values.postalCode}
            onChange={onChange}
            className="edit-profile__input2"
          />
        </Form.Group>
      </Row>
    </Fragment>
  );
};
export default AddressForm;
