import React, { useCallback } from "react";
import { useState } from "react";
import {
  convertToCurrency,
  convertToEnrollmentFeeDetails,
  convertToOneTimeDiscountDetails,
  convertToOneTimePackageDetails,
} from "../utility/util";
import axiosInstance from "../axios.instance";
import {
  IUser,
  PROGRAM_CODE,
  USER_PAYROLL_FREQUENCY,
  USER_PAYROLL_STATUS,
} from "./UserProvider";
import { FormikWizardState, EligibilityFlow } from "../components/wizard/types";
import { v4 as uuidv4 } from "uuid";
import { AccountSummaryMember } from "../utility/member-portal-api-types";
import { Product } from "../components/wizard/forms/Package";
import { useConfig } from "../configuration/useConfig";
import { PlanDependent } from "../components/wizard/components/ManageMembers";
import getDependentProgramCode from "../utility/getDependentProgramCode";

type PaymentPage = {
  hostedPaymentPageId: string;
  hostedPaymentPageUrl: string;
  key: string;
  signature: string;
  tenantId: string;
  token: string;
};

export type PackageDetails = {
  name: string;
  subtotal: number;
  quantity?: number;
};

export type ImmediatePaymentPackages = {
  amountWithoutTax: number;
  amountWithTax: number;
  chargeName: string;
  consumerId: string;
  chargeType: string;
  serviceStartDate: string;
  serviceEndDate: string;
  tax: number;
  tierId: number;
};

type PromoCodeDetails = {
  code: string;
  status: string;
  description: string;
};

type SummaryResponse = {
  orders: [];
  promoCodeError: string[];
  reasons?: [];
  immediatePaymentSummary: {
    immediatePaymentDetails: [
      {
        amountWithoutTax: number;
        amountWithTax: number;
        chargeName: string;
        consumerId: string;
        chargeType: string;
        serviceStartDate: string;
        serviceEndDate: string;
        tax: number;
        tierId: number;
      }
    ];
    promoCodeDescription: [];
    detailedPromoCodeDescription: [];
    creditAmountWithoutTax: number;
    creditAmountWithTax: number;
    creditTaxAmount: number;
    immediateTotalPaymentAmountWithoutTax: number;
    immediateTotalPaymentAmountWithTax: number;
    immediateTotalPaymentTaxAmount: number;
    immediatePaymentDiscountAmountWithoutTax: number;
    immediatePaymentDiscountTaxAmount: number;
    immediateTotalPaymentAfterDiscountAmountWithoutTax: number;
    immediateTotalPaymentAfterDiscountAmountWithTax: number;
    immediateTotalPaymentAfterDiscountTaxAmount: number;
    immediateTotalPaymentSetUpFee: number;
    immediateTotalPaymentRecurringFee: number;
  };
  monthlyPaymentSummary: {
    monthlyPaymentDetails: [
      {
        amountWithoutTax: number;
        amountWithTax: number;
        chargeName: string;
        consumerId: string;
        chargeType: string;
        serviceStartDate: string;
        serviceEndDate: string;
        tax: number;
        tierId: number;
      }
    ];
    promoCodeDescription: [];
    detailedPromoCodeDescription: [];
    monthlyTotalPaymentAmountWithoutTax: number;
    monthlyTotalPaymentAmountWithTax: number;
    monthlyTotalPaymentTaxAmount: number;
    monthlyPaymentDiscountAmountWithoutTax: number;
    monthlyPaymentDiscountTaxAmount: number;
    monthlyTotalPaymentAfterDiscountAmountWithoutTax: number;
    monthlyTotalPaymentAfterDiscountAmountWithTax: number;
    monthlyTotalPaymentAfterDiscountTaxAmount: number;
    monthlyTotalPaymentSetUpFee: number;
    monthlyTotalPaymentRecurringFee: number;
  };
  paymentId: string;
  paymentNumber: string;
  processId: string;
  success: boolean;
  status?: string;
};

type SummaryType = {
  sku: string;
  handle: string;
  onetimeCreditTotal: number;
  onetimeDiscount: number;
  onetimeDiscounts: string[];
  onetimePackageDetails: PackageDetails[] | null;
  onetimePackageFeeDetails: PackageDetails[] | null;
  onetimeDiscountDetails: PackageDetails[] | null;
  onetimeSubtotal: number;
  subtotalWithTax: number;
  onetimeSubtotalAfterDiscount: number; //before tax after discount
  onetimeTaxes: number;
  onetimeTotal: number;
  onetimeSetupFee: number;
  periodicDiscount: number;
  periodicDiscounts: string[];
  periodicPackageDetails: PackageDetails[];
  periodicSubtotal: number;
  periodicTaxes: number;
  periodicTotal: number;
  periodicServiceStartDate: string;
  // promoCode: string;
  // promoCodeDescription: string;
  // promoCodeStatus: string;
  promoCodes: PromoCodeDetails[];
  paymentPageCC: PaymentPage;
  paymentPageECheck: PaymentPage;
  paymentMethodRequired: boolean;
  taxCalculated: boolean;
  totalDueNow: number;
  monthlyTotalAfterDiscountWithTax: number;
};

export type SummaryUIMap = {
  pending?: {
    //edit plan summary only
    planTotal: string;
    planList: PackageDetails[];
    discounts: string[];
    showDiscounts: boolean;
    discountsTotal: string;
    taxes: string;
    total: string;
    startDate: string;
  };
  due?: {
    //edit plan summary only
    planTotal: string;
    planList: PackageDetails[];
    credits: string;
    discounts: string[];
    discountsTotal: string;
    discountsTotalMinusDigital: string;
    promoCodes: PromoCodeDetails[];
    // promoCode: string;
    // promoCodeDescription: string;
    // promoCodeStatus: string;
    subtotal: string;
    enrollmentFee?: string;
    taxes: string;
    total: string;
  };
  checkout?: {
    //can be used in checkout summary, possibly in edit plan
    planTotal: string;
    planList: PackageDetails[];
    planListFees: PackageDetails[];
    planListDiscounts: PackageDetails[];
    enrollmentFee?: string;
    discounts: string[];
    showDiscounts: boolean;
    discountsTotal: string;
    discountsTotalMinusDigital: string;
    subtotal: string;
    startDate: string;
    monthlyTotal: string;
    monthlyTotalNoTaxes: string;
    total: string;
    subtotalWithTax: string;
    taxes: string;
    totalDueNow: string;
    monthlyTotalAfterDiscountWithTax: string;
  };
};

type SummaryContextType = {
  forceRefresh: () => void;
  getSummaryRatePlan: (wizardState: FormikWizardState<EligibilityFlow>) => void;
  getPreviewTierChange: (
    member: AccountSummaryMember,
    user: IUser,
    values: FormikWizardState<EligibilityFlow>,
    token: string
  ) => void;
  setMutated: () => void;
  cancel: () => void;
  mutated: boolean;
  force: boolean;
  setPromoCode(c: string): void;
  // setAddendum: (value?: string) => void;
  // setProductRatePlans: (value?: Product[]) => void;
  setAddendum(a: string): void;
  setProductRatePlans(p: Product[]): void;
  updateSummaryWithPromoCode: (
    wizardState: FormikWizardState<EligibilityFlow>,
    promoCode: string
  ) => void;
  summary: SummaryType | undefined;
  error: boolean;
  loading: boolean;
  promoLoading: boolean;
  uiSummary: SummaryUIMap | null;
  // productRatesLoaded: boolean;
  productRatePlans?: Product[];
  addendum?: string;
  promoCode?: string;
  promoCodeError: string;
};

export const SummaryContext = React.createContext<SummaryContextType>({
  forceRefresh: () => {
    return;
  },
  getSummaryRatePlan: () => {
    return;
  },
  getPreviewTierChange: () => {
    return;
  },
  uiSummary: {},
  setMutated: () => {
    return;
  },
  mutated: false,
  cancel: () => {
    return;
  },
  force: false,
  setPromoCode: () => {
    return;
  },
  setAddendum: () => {
    return;
  },
  setProductRatePlans: () => {
    return;
  },
  updateSummaryWithPromoCode: () => {
    return;
  },
  summary: undefined,
  error: false,
  loading: true,
  promoLoading: false,
  productRatePlans: [],
  // productRatesLoaded:false
  promoCodeError: "",
});

// TODO - use a reducer pattern
const SummaryProvider = ({ children }: { children: React.ReactNode }) => {
  const initialState = {
    mutated: false,
    force: false,
    shouldPoll: false,
    isLoading: false,
    promoLoading: false,
    errorRequest: false,
  };
  const [productRatePlans, setProductRatePlans] = useState<Product[]>([]);
  const [addendum, setAddendum] = useState<string>("Per Paycheck");
  // const [productRatesLoaded, setProductRatesLoaded] = useState<string>("Per Paycheck");
  // productRatesLoaded
  const [promoCode, setPromoCode] = useState<string>("");

  const [summary, setSummary] = useState<SummaryType>();
  // const [uuid, setUuid] = useState(uuidv4())
  const myuuid = uuidv4();
  let dependent;

  const [state] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [promoCodeError, setPromoCodeError] = useState<string>("");

  const { config } = useConfig();

  let uiMapping: SummaryUIMap | null = null;

  if (summary) {
    // Alter the way digital plan shows when included as part of another package
    uiMapping = {
      checkout: {
        planTotal: convertToCurrency(summary.onetimeSubtotal),
        planList:
          summary.onetimePackageDetails?.sort((a, b) =>
            a.name.localeCompare(b.name)
          ) ?? [],
        planListFees:
          summary.onetimePackageFeeDetails?.sort((a, b) =>
            a.name.localeCompare(b.name)
          ) ?? [],
        discounts:
          summary.onetimeDiscounts.filter(
            (discount) => discount !== "Digital Discount"
          ) ?? [],
        planListDiscounts: summary.onetimeDiscountDetails ?? [],
        showDiscounts: summary.promoCodes.length === 0 ? false : true,
        discountsTotal: convertToCurrency(summary?.onetimeDiscount ?? 0),
        discountsTotalMinusDigital: convertToCurrency(
          summary.onetimeDiscount + 10
        ),
        subtotal: convertToCurrency(summary?.onetimeSubtotalAfterDiscount ?? 0),
        subtotalWithTax: convertToCurrency(summary?.subtotalWithTax ?? 0),
        monthlyTotal: convertToCurrency(summary?.periodicTotal ?? 0),
        monthlyTotalNoTaxes: convertToCurrency(summary?.onetimeTotal ?? 0),
        startDate: new Date(
          `${summary.periodicServiceStartDate}T00:00:00` ?? ""
        ).toLocaleDateString("en-US", {
          timeZone: "UTC",
          month: "long",
          day: "numeric",
        }),
        total: convertToCurrency(summary?.onetimeTotal ?? 0),
        taxes: convertToCurrency(summary?.onetimeTaxes ?? 0),
        enrollmentFee: convertToCurrency(summary?.onetimeSetupFee ?? 0),
        totalDueNow: convertToCurrency(summary?.totalDueNow ?? 0),
        monthlyTotalAfterDiscountWithTax: convertToCurrency(
          summary?.monthlyTotalAfterDiscountWithTax ?? 0
        ),
      },
      due: {
        credits: convertToCurrency(summary.onetimeCreditTotal ?? 0),
        discounts:
          summary.onetimeDiscounts.filter(
            (discount) => discount !== "Digital Discount"
          ) ?? [],
        discountsTotal: convertToCurrency(summary.onetimeDiscount ?? 0),
        discountsTotalMinusDigital: convertToCurrency(
          summary.onetimeDiscount - 10
        ),
        planTotal: convertToCurrency(
          summary.onetimePackageDetails !== null
            ? summary.onetimePackageDetails.reduce((a, b) => a + b.subtotal, 0)
            : 0
        ),
        planList: summary.onetimePackageDetails ?? [],
        promoCodes: summary.promoCodes ?? [],
        // promoCode: summary.promoCode ?? "",
        // promoCodeDescription: summary.promoCodeDescription,
        // promoCodeStatus: summary.promoCodeStatus,
        //TODO -NEED THIS
        subtotal: convertToCurrency(summary.onetimeSubtotalAfterDiscount ?? 0),
        enrollmentFee: convertToCurrency(summary.onetimeSetupFee ?? 0),
        taxes: convertToCurrency(summary.onetimeTaxes ?? 0),
        total: convertToCurrency(summary.onetimeTotal ?? 0),
      },
      pending: {
        total: convertToCurrency(summary.periodicTotal ?? 0),
        discounts:
          summary.periodicDiscounts.filter(
            (discount) => discount !== "Digital Discount"
          ) ?? [],
        showDiscounts: summary.periodicDiscounts.some((discount) =>
          discount !== "Digital Discount" ? true : false
        ),
        discountsTotal: convertToCurrency(summary.periodicDiscount ?? 0),
        planTotal: convertToCurrency(
          summary.periodicPackageDetails.length > 1
            ? summary.periodicPackageDetails
                .filter((plan) => plan.name !== "Digital (included)")
                .reduce((a, b) => a + b.subtotal, 0)
            : summary.periodicPackageDetails[0]?.subtotal
        ),
        planList:
          summary.periodicPackageDetails.sort((a, b) =>
            a.name.localeCompare(b.name)
          ) ?? [],
        startDate: new Date(
          `${summary.periodicServiceStartDate}T00:00:00` ?? ""
        ).toLocaleDateString("en-US", {
          timeZone: "UTC",
          month: "long",
          day: "numeric",
        }),
        taxes: convertToCurrency(summary.periodicTaxes) ?? "$0.00",
      },
    };
  }

  const getDependentRatePlanChargeId = (
    dependentRatePlanChargeId: string | undefined,
    dependentPackageName: string | undefined,
    primaryRatePlanChargeId: string | undefined,
    primaryPackageName: string | undefined
  ): string[] | undefined => {
    if (dependentPackageName === PROGRAM_CODE.Studio) {
      return undefined;
    }

    if (dependentRatePlanChargeId) {
      return [dependentRatePlanChargeId];
    }

    if (primaryPackageName !== PROGRAM_CODE.Studio && primaryRatePlanChargeId) {
      return [primaryRatePlanChargeId];
    }

    if (
      primaryPackageName === PROGRAM_CODE.Studio &&
      !dependentRatePlanChargeId
    ) {
      return [productRatePlans[0].recurringRatePlanChargeId];
    }

    return undefined;
  };

  const createSummaryBody = (
    wizardState: FormikWizardState<EligibilityFlow>,
    currentPromoCode?: string
  ) => {
    const isStudio = wizardState?.package?.sku === PROGRAM_CODE.Studio;
    const deps = wizardState?.eligibility?.dependents as PlanDependent[];
    const billToContact = wizardState?.address?.billingRequired
      ? wizardState?.address?.billing
      : wizardState?.address?.home;
    const soldToContact = wizardState?.address?.home;

    const primary = {
      billToContact: {
        lastName: wizardState.eligibility?.lastName,
        firstName: wizardState.eligibility?.firstName,
        address1: billToContact?.street1,
        address2: billToContact?.street2,
        city: billToContact?.city,
        country: "United States",
        postalCode: billToContact?.zip,
        state: billToContact?.state,
      },
      consumerId: myuuid,
      lastName: wizardState.eligibility?.lastName,
      firstName: wizardState.eligibility?.firstName,
      isPrimary: true,
      dateOfBirth: `${wizardState.eligibility?.year}-${wizardState.eligibility?.month}-${wizardState.eligibility?.day}`,
      orderActions: [
        {
          type: "NewAccount",
          ratePlanChargeIds: !isStudio
            ? [wizardState.package?.ratePlanChargeId]
            : undefined,
        },
      ],
      soldToContact: {
        firstName: wizardState.eligibility?.firstName,
        lastName: wizardState.eligibility?.lastName,
        address1: soldToContact?.street1,
        address2: soldToContact?.street2,
        city: soldToContact?.city,
        country: "United States",
        id: "abc123",
        postalCode: soldToContact?.zip,
        state: soldToContact?.state,
        email: wizardState.eligibility?.email,
      },
      programCode:
        config["client"] === "walmart"
          ? wizardState.eligibility?.isPayroll
            ? wizardState.eligibility?.payrollFrequency ===
              USER_PAYROLL_FREQUENCY.BiWeekly
              ? PROGRAM_CODE.WalmartPayrollBiWeekly
              : PROGRAM_CODE.WalmartPayrollWeekly
            : PROGRAM_CODE.PrimeLTE
          : isStudio
          ? PROGRAM_CODE.Studio
          : PROGRAM_CODE.PrimeSTD,
    };

    if (deps) {
      const eligibleDependents = deps
        ?.filter(
          (dependent) =>
            (dependent.status === "Eligible" && dependent.isChosen) ||
            (dependent.status === "UnderAgeDependent" && dependent.isChosen)
        )
        .map((eligibleDependent) => ({ ...eligibleDependent }));

      dependent = eligibleDependents?.map((dependent) => ({
        billToContact: {
          lastName: wizardState?.eligibility?.lastName ?? "",
          firstName: wizardState?.eligibility?.firstName ?? "",
          address1: billToContact?.street1 ?? "",
          address2: billToContact?.street2 ?? "",
          city: billToContact?.city ?? "",
          postalCode: billToContact?.zip ?? "",
          state: billToContact?.state ?? "",
        },
        consumerId: uuidv4(),
        lastName: dependent.lastName ?? "",
        firstName: dependent.firstName ?? "",
        isPrimary: false,
        dateOfBirth: dependent.dateOfBirth ?? "",
        orderActions: [
          {
            type: "NewAccount",
            ratePlanChargeIds: getDependentRatePlanChargeId(
              dependent.ratePlanChargeId,
              dependent.sku,
              wizardState.package?.ratePlanChargeId,
              wizardState.package?.sku
            ),
          },
        ],
        soldToContact: {
          firstName: dependent.firstName ?? "",
          lastName: dependent.lastName ?? "",
          address1: soldToContact?.street1 ?? "",
          address2: soldToContact?.street2 ?? "",
          city: soldToContact?.city ?? "",
          postalCode: soldToContact?.zip ?? "",
          state: soldToContact?.state ?? "",
          email: dependent.email
            ? dependent.email
            : wizardState.eligibility?.email,
        },
        programCode: getDependentProgramCode(
          config["client"],
          wizardState.eligibility?.isPayroll,
          dependent.sku,
          wizardState.package?.sku
        ),
      }));
    }

    let payloadMembers;

    if (dependent) {
      payloadMembers = [primary, ...dependent];
    } else {
      payloadMembers = [primary];
    }

    const body = {
      payrollStatus:
        config["client"] === "walmart"
          ? wizardState.eligibility?.isPayroll
            ? USER_PAYROLL_STATUS.Payroll
            : USER_PAYROLL_STATUS.NonPayroll
          : USER_PAYROLL_STATUS.NotApplicable,
      members: payloadMembers,
      promoCodes: [currentPromoCode || promoCode],
    };
    return body;
  };

  const updateSummary = (responseData: SummaryResponse) => {
    setSummary({
      sku: "",
      handle: "res.orderHandle",
      onetimeCreditTotal: 1.0,
      onetimeDiscount:
        responseData.immediatePaymentSummary
          .immediatePaymentDiscountAmountWithoutTax,
      onetimeDiscounts: [],
      onetimePackageDetails: convertToOneTimePackageDetails(
        responseData.immediatePaymentSummary.immediatePaymentDetails
      ),
      onetimePackageFeeDetails: convertToEnrollmentFeeDetails(
        responseData.immediatePaymentSummary.immediatePaymentDetails
      ),
      onetimeDiscountDetails: convertToOneTimeDiscountDetails(
        responseData.immediatePaymentSummary.immediatePaymentDetails
      ),
      onetimeSubtotal:
        responseData.immediatePaymentSummary
          .immediateTotalPaymentAmountWithoutTax,
      subtotalWithTax:
        responseData.immediatePaymentSummary.immediateTotalPaymentAmountWithTax,
      onetimeSubtotalAfterDiscount:
        responseData.immediatePaymentSummary
          .immediateTotalPaymentAmountWithoutTax,
      onetimeTaxes:
        responseData.immediatePaymentSummary
          .immediateTotalPaymentAfterDiscountTaxAmount,
      onetimeTotal:
        responseData.immediatePaymentSummary.immediateTotalPaymentRecurringFee,
      onetimeSetupFee:
        responseData.immediatePaymentSummary.immediateTotalPaymentSetUpFee,
      periodicDiscount:
        responseData.immediatePaymentSummary
          .immediateTotalPaymentAmountWithoutTax,
      periodicDiscounts: [],
      periodicPackageDetails: [],
      periodicSubtotal:
        responseData.monthlyPaymentSummary.monthlyTotalPaymentAmountWithTax,
      periodicTotal:
        responseData.monthlyPaymentSummary.monthlyTotalPaymentAmountWithTax,
      periodicServiceStartDate:
        responseData.monthlyPaymentSummary.monthlyPaymentDetails[0]
          ?.serviceStartDate ?? "",
      paymentMethodRequired: true,
      periodicTaxes:
        responseData.monthlyPaymentSummary.monthlyTotalPaymentTaxAmount,
      totalDueNow:
        responseData.immediatePaymentSummary
          .immediateTotalPaymentAfterDiscountAmountWithTax,
      monthlyTotalAfterDiscountWithTax:
        responseData.monthlyPaymentSummary
          .monthlyTotalPaymentAfterDiscountAmountWithTax,
      promoCodes: [],
      // promoCode: res.order.promoCode,
      // promoCodeDescription: res.order.promoCodeDescription,
      // promoCodeStatus: res.order.promoCodeStatus,
      taxCalculated: true,
      paymentPageCC: {
        hostedPaymentPageId: "",
        hostedPaymentPageUrl: "",
        key: "",
        signature: "",
        tenantId: "",
        token: "",
      },
      paymentPageECheck: {
        hostedPaymentPageId: "",
        hostedPaymentPageUrl: "",
        key: "",
        signature: "",
        tenantId: "",
        token: "",
      },
    });
  };

  const checkPromoCodeError = (data: SummaryResponse) => {
    if (data.promoCodeError.length !== 0) {
      setPromoCodeError(data.promoCodeError[0]);
    } else {
      setPromoCodeError("");
    }
  };

  const getSummary = (wizardState: FormikWizardState<EligibilityFlow>) => {
    const body = createSummaryBody(wizardState);

    setLoading(true);
    setError(false);
    axiosInstance
      .post<SummaryResponse>("/enrollment/preview", body)
      .catch((err) => {
        console.log(err.toJSON());
        setLoading(false);
        setError(true);
        throw err;
      })
      .then((response) => {
        if (response.data) {
          updateSummary(response.data);
          setLoading(false);
        }
      });
  };

  const getPreviewTierChange = (
    member: AccountSummaryMember,
    user: IUser,
    values: FormikWizardState<EligibilityFlow>,
    token: string
  ) => {
    axiosInstance
      .post(
        "/account/subscriptions/preview",
        {
          members: [
            {
              personId: user.personId,
              payrollStatus:
                config["client"] === "walmart"
                  ? values.eligibility?.isPayroll
                    ? USER_PAYROLL_STATUS.Payroll
                    : USER_PAYROLL_STATUS.NonPayroll
                  : USER_PAYROLL_STATUS.NotApplicable,
              clientStateCode: values.eligibility?.clientStateCode,
              subscriberId: values.eligibility?.associateIdentificationNumber,
              orderActions: [
                {
                  ratePlanChargeId: values.package?.ratePlanChargeId,
                  type: "SubscriptionNew",
                },
              ],
            },
          ],
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setSummary({
          sku: "",
          handle: "res.orderHandle",
          onetimeCreditTotal: 1.0,
          onetimeDiscount:
            response.data.immediatePaymentSummary
              .immediatePaymentDiscountAmountWithoutTax,
          onetimeDiscounts: [],
          onetimePackageDetails: convertToOneTimePackageDetails(
            response.data.immediatePaymentSummary.immediatePaymentDetails
          ),
          onetimePackageFeeDetails: convertToEnrollmentFeeDetails(
            response.data.immediatePaymentSummary.immediatePaymentDetails
          ),
          onetimeDiscountDetails: convertToOneTimeDiscountDetails(
            response.data.immediatePaymentSummary.immediatePaymentDetails
          ),
          onetimeSubtotal:
            response.data.immediatePaymentSummary
              .immediateTotalPaymentAmountWithoutTax,
          subtotalWithTax:
            response.data.immediatePaymentSummary
              .immediateTotalPaymentAmountWithTax,
          onetimeSubtotalAfterDiscount:
            response.data.immediatePaymentSummary
              .immediateTotalPaymentAmountWithoutTax,
          onetimeTaxes:
            response.data.immediatePaymentSummary
              .immediateTotalPaymentTaxAmount,
          onetimeTotal:
            response.data.immediatePaymentSummary
              .immediateTotalPaymentAfterDiscountAmountWithTax,
          onetimeSetupFee:
            response.data.immediatePaymentSummary
              .immediateTotalPaymentAmountWithoutTax,
          periodicDiscount:
            response.data.immediatePaymentSummary
              .immediateTotalPaymentAmountWithoutTax,
          periodicDiscounts: [],
          periodicPackageDetails: [],
          periodicSubtotal:
            response.data.monthlyPaymentSummary
              .monthlyTotalPaymentAmountWithTax,
          periodicTotal:
            response.data.monthlyPaymentSummary
              .monthlyTotalPaymentAmountWithTax,
          periodicServiceStartDate:
            response.data.monthlyPaymentSummary.monthlyPaymentDetails[0]
              ?.serviceStartDate ?? "",
          paymentMethodRequired: true,
          periodicTaxes:
            response.data.monthlyPaymentSummary.monthlyTotalPaymentTaxAmount,
          totalDueNow:
            response.data.immediatePaymentSummary
              .immediateTotalPaymentAmountWithoutTax,
          monthlyTotalAfterDiscountWithTax:
            response.data.monthlyPaymentSummary
              .monthlyTotalPaymentAfterDiscountAmountWithTax,
          promoCodes: [],
          // promoCode: res.order.promoCode,
          // promoCodeDescription: res.order.promoCodeDescription,
          // promoCodeStatus: res.order.promoCodeStatus,
          taxCalculated: true,
          paymentPageCC: {
            hostedPaymentPageId: "",
            hostedPaymentPageUrl: "",
            key: "",
            signature: "",
            tenantId: "",
            token: "",
          },
          paymentPageECheck: {
            hostedPaymentPageId: "",
            hostedPaymentPageUrl: "",
            key: "",
            signature: "",
            tenantId: "",
            token: "",
          },
        });
      });
  };

  const forceRefresh = () => {
    // getSummary();
    // setState((s) => ({
    //   ...s,
    //   force: true,
    //   mutated: true,
    // }));
  };
  const setMutated = () => {
    return;
  };

  const cancel = () => {
    return;
  };

  const getSummaryRatePlan = (
    wizardState: FormikWizardState<EligibilityFlow>
  ) => {
    getSummary(wizardState);
  };

  const updateSummaryWithPromoCode = (
    wizardState: FormikWizardState<EligibilityFlow>,
    promoCode: string
  ) => {
    setLoading(true);
    setError(false);
    const body = createSummaryBody(wizardState, promoCode);
    axiosInstance
      .post<SummaryResponse>("/enrollment/preview", body)
      .catch((err) => {
        console.log(err.toJSON());
        setLoading(false);
        setError(true);
        throw err;
      })
      .then((response) => {
        if (response.data) {
          checkPromoCodeError(response.data);
          updateSummary(response.data);
          setLoading(false);
        }
      });
  };

  return (
    <SummaryContext.Provider
      value={{
        setMutated,
        cancel,
        forceRefresh,
        setPromoCode: (c: string) => {
          setPromoCode(c);
        },
        getSummaryRatePlan,
        getPreviewTierChange,
        setProductRatePlans: (p: Product[]) => {
          setProductRatePlans(p);
        },
        setAddendum: useCallback(
          (a: string) => {
            setAddendum(a);
          },
          [addendum]
        ),
        updateSummaryWithPromoCode,
        mutated: state.mutated,
        summary: summary,
        error: error,
        loading: loading,
        force: state.force,
        promoLoading: state.promoLoading,
        uiSummary: uiMapping,
        productRatePlans,
        addendum,
        promoCodeError,
      }}
    >
      {children}
    </SummaryContext.Provider>
  );
};

export default SummaryProvider;
