import { PROGRAM_CODE, USER_PAYROLL_FREQUENCY } from "../context/UserProvider";

const getDependentProgramCode = (
  client,
  isPayroll,
  dependentPackageName,
  primaryPackageName,
  payrollFrequency
) => {
  if (client === "walmart") {
    return isPayroll ? payrollFrequency === USER_PAYROLL_FREQUENCY.BiWeekly ? PROGRAM_CODE.WalmartPayrollBiWeekly : PROGRAM_CODE.WalmartPayrollWeekly : PROGRAM_CODE.PrimeLTE;
  }

  if (primaryPackageName === PROGRAM_CODE.Studio && !dependentPackageName) {
    return PROGRAM_CODE.PrimeSTD
  }

  let packageName = primaryPackageName;
  if (dependentPackageName) {
    packageName = dependentPackageName;
  }

  return packageName === PROGRAM_CODE.Studio ? PROGRAM_CODE.Studio: PROGRAM_CODE.PrimeSTD;
}

export default getDependentProgramCode;
