import React, { useEffect, useState } from "react";
import useGetPrograms from "../../hooks/useGetPrograms";
import { Product } from "../wizard/forms/Package";
import { PROGRAM_CODE } from "../../context/UserProvider";

const BundlesDropDownUnderageDependent = ({
  membersPage,
  highestSelectableTier,
  primaryTier,
  isFuturePlan = false,
  loaded,
}: {
  planId?: string;
  membersPage?: boolean;
  isFuturePlan?: boolean;
  highestSelectableTier: string;
  primaryTier: string;
  loaded: () => void;
}) => {
  const primaryTierNumber = parseInt(primaryTier);
  const highestSelectableTierNumber = parseInt(highestSelectableTier);
  const { error, loading, productRatePlans, addendum, programCode } =
    useGetPrograms();
  const [filteredProductRatePlans, setFilteredProductRatePlans] = useState<
    Product[]
  >([]);
  const [filtered, setFiltered] = useState<boolean>(false);

  useEffect(() => {
    if (
      !isFuturePlan &&
      !loading &&
      programCode === PROGRAM_CODE.PrimeSTD &&
      primaryTierNumber >= 4
    ) {
      setFilteredProductRatePlans(
        productRatePlans.filter(
          (plan) => plan.tier <= 3 || plan.tier === primaryTierNumber
        )
      );
      setFiltered(true);
      loaded();
    } else if (!loading) {
      setFilteredProductRatePlans(
        productRatePlans.filter(
          (plan) => plan.tier <= highestSelectableTierNumber
        )
      );
      setFiltered(true);
      loaded();
    }
  }, [loading, primaryTierNumber]);

  if (loading && !filtered) {
    return <option>Loading...</option>;
  }
  if (error) {
    return <option>Error getting pricing</option>;
  }

  return (
    <>
      {/* {termed ? (
        <option value={"termedDefault"} key={"termedDefault"} disabled>
          Please Select a Package
        </option>
      ) : null} */}
      {filteredProductRatePlans.map((plan) => {
        if (plan.name !== "studio") {
          return (
            <option
              // defaultValue={planId}
              value={plan.recurringRatePlanChargeId}
              key={plan.id}
            >
              {plan.name.toUpperCase()}{" "}
              {membersPage && ` + Studio Rewards + Digital Content`} {" - "}{" "}
              {`$${plan.recurringPrice.toFixed(2) ?? 0}/${addendum} `}
            </option>
          );
        }
      })}
    </>
  );
};

export default BundlesDropDownUnderageDependent;
