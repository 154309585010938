import React from "react";
import SomethingWentWrongImg from "../resources/SomethingWentWrong.svg";
import { useHistory } from "react-router-dom";
import { useConfig } from "../configuration/useConfig";

type Error = {
  message: string;
};

const SomethingWentWrong = ({
  small,
  backToSafety,
}: {
  error?: Error;
  small?: boolean;
  backToSafety?: boolean;
}) => {
  const history = useHistory();
  const { config } = useConfig();

  return (
    <div className="not-found-container">
      <div className="not-found-container__body">
        {!backToSafety ? (
          <img
            alt="Fitness Your Way"
            src={
              config["client"] === "walmart"
                ? "/Tivity_fitness_pass_full_512.png"
                : config["logo"]
            }
            width="300"
            className={`d-inline-block align-top ${small ? `mb-2` : `mb-5`}`}
          />
        ) : null}
        <img
          src={SomethingWentWrongImg}
          alt="Wrong"
          className={`not-found-container__SomethingWentWrongImg ${
            small ? `mt-2` : `mt-5`
          }`}
        />
        <span className="not-found-container__body__header">
          Something Went Wrong
        </span>

        <p>To resolve this issue please call us at: </p>
        <a
          href={`tel:${config["contact.phoneNumber"]}`}
          className="not-found-container__body__phone mb-0"
        >
          {config["contact.phoneNumber"]}
        </a>

        <span>Monday - Friday, 7 a.m. - 7 p.m., CT (6 a.m. - 6 p.m. MT)</span>

        {backToSafety ? (
          <button onClick={() => history.push("/")}>Back to Safety</button>
        ) : null}
      </div>
    </div>
  );
};

export default SomethingWentWrong;
