import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  // Button,
  Card,
  Col,
  Collapse,
  Fade,
  // Form,
  // FormControl,
  // InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import useSummary from "../../../hooks/useSummary";
import CollapseHeader from "../../shared/CollapseHeader";
import { convertToCurrency } from "../../../utility/util";
import { useFormikContext } from "formik";
import { FormikWizardState, EligibilityFlow } from "../types";
import { PROGRAM_CODE, UserContext } from "../../../context/UserProvider";
import { AccountSummaryMember } from "../../../utility/member-portal-api-types";
import useGetPrograms from "../../../hooks/useGetPrograms";
import { useAuthToken } from "../../shared/Auth0TokenProvider";
import { useConfig } from "../../../configuration/useConfig";
import useGetScreenSize from "../../../hooks/useGetScreenSize";

const Summary = () => {
  const {
    uiSummary,
    loading,
    error,
    promoLoading,
    getSummaryRatePlan,
    getPreviewTierChange,
    setProductRatePlans, //TODO - Used for mockup, remove after implementation
    setPromoCode,
    summary,
    updateSummaryWithPromoCode,
    promoCodeError,
  } = useSummary();

  const [show, setShow] = useState(!loading);
  const [promo, setPromoState] = useState("");
  const [isStudioOnly, setIsStudioOnly] = useState(false);
  const [showStartDate, setShowStartDate] = useState(true);

  const token = useAuthToken();
  const { values } = useFormikContext<FormikWizardState<EligibilityFlow>>();
  const { user } = useContext(UserContext);
  const member = values.eligibility?.member as AccountSummaryMember;
  const { config } = useConfig();
  const { isSmallScreen } = useGetScreenSize();

  const { productRatePlans } = useGetPrograms();
  setProductRatePlans(productRatePlans);

  useEffect(() => {
    if (loading || error || promoLoading) {
      setShow(false);
    }
  }, [loading, error, promoLoading]);

  useEffect(() => {
    if (member?.personId && token) {
      getPreviewTierChange(member, user, values, token);
    } else {
      getSummaryRatePlan(values);
    }
  }, []);

  useEffect(() => {
    setIsStudioOnly(values.package?.programCode === PROGRAM_CODE.Studio);
  }, [values.package?.programCode]);

  useEffect(() => {
    if (
      isStudioOnly &&
      summary?.subtotalWithTax === 0 &&
      summary?.periodicTotal === 0
    ) {
      setShowStartDate(false);
    } else {
      setShowStartDate(true);
    }
  }, [isStudioOnly, summary]);

  const formatContent = (title: string, cost?: string, quantity?: number) => {
    return (
      <p className="mb-1">
        <i>
          {quantity !== undefined && quantity > 1
            ? `(${quantity}) ${title}`
            : title}
        </i>
        {cost ? <span style={{ float: "right" }}>{cost}</span> : null}
      </p>
    );
  };

  const handlePromoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromoState(e.target.value);
  };

  const handlePromoSubmit = async () => {
    if (summary?.promoCodes.length === 0) {
      setPromoCode(promo);
    } else {
      const str = summary?.promoCodes
        .map(function (elem) {
          if (elem.code !== promo) return elem.code;
        })
        .join(",");
      setPromoCode(promo + "," + str);
    }
    if (promo) {
      updateSummaryWithPromoCode(values, promo);
    }
  };

  return (
    <Card className="edit-plan__summary">
      <Card.Body>
        <Fade in={error} unmountOnExit mountOnEnter>
          <div className="summary-container__error">
            <h4>
              <b>Sorry, there was an error.</b>
            </h4>
          </div>
        </Fade>

        <Fade
          in={loading && !error && !promoLoading}
          unmountOnExit
          onExited={() => setShow(true)}
          appear
        >
          <div className="summary-container__loading">
            <h4>Getting your Order Summary</h4>
            <Spinner animation="border" />
          </div>
        </Fade>

        <Collapse in={show && !error} mountOnEnter>
          <div>
            <Card.Title>
              <h6>Summary</h6>
            </Card.Title>
            <div className="summary-container_region m-2">
              <div className="enrollment-container">
                <CollapseHeader
                  header="Package Selection(s) Total"
                  price={uiSummary?.checkout?.monthlyTotalNoTaxes ?? "0.00"}
                >
                  <>
                    {isStudioOnly ? (
                      <p className="mb-1">
                        <i>Studio Class Rewards</i>
                        <span style={{ float: "right" }}>$0.00</span>
                      </p>
                    ) : (
                      <></>
                    )}
                    {uiSummary?.checkout?.planList.map((p) => (
                      <>
                        {formatContent(
                          p.name,
                          convertToCurrency(p.subtotal),
                          p.quantity
                        )}
                      </>
                    ))}
                  </>
                </CollapseHeader>
              </div>
              {config["client"] !== "walmart" ? (
                <div className="enrollment-container">
                  <CollapseHeader
                    header="Enrollment Fees"
                    price={uiSummary?.checkout?.enrollmentFee ?? "0.00"}
                  >
                    <>
                      {uiSummary?.checkout?.planListFees.map((p) => (
                        <>
                          {formatContent(
                            p.name,
                            convertToCurrency(p.subtotal),
                            p.quantity
                          )}
                        </>
                      ))}
                    </>
                  </CollapseHeader>
                </div>
              ) : null}

              {uiSummary?.checkout?.planListDiscounts.length !== 0 ? (
                <div className="enrollment-container">
                  <CollapseHeader
                    header="Discount(s) Total"
                    price={uiSummary?.checkout?.discountsTotal ?? "0.00"}
                  >
                    <>
                      {uiSummary?.checkout?.planListDiscounts.map((p) => (
                        <>{formatContent(p.name, "", p.quantity)}</>
                      ))}
                    </>
                  </CollapseHeader>
                </div>
              ) : null}
              {/* {uiSummary?.checkout?.discounts.length !== 0 ? (
                <div className="enrollment-container mt-2">
                  <CollapseHeader
                    header="Discount(s) Total"
                    price={uiSummary?.checkout?.discountsTotal ?? "0.00"}
                  >
                    <>
                      {uiSummary?.checkout?.discounts.map((p) => (
                        <div>{p}</div>
                      ))}
                    </>
                  </CollapseHeader>
                </div>
              ) : null} */}
              {config["client"] !== "walmart" ? (
                <div className="enrollment-container">
                  <p className="font-weight-bold text-uppercase text-secondary mb-0 small">
                    Have a Promo Code?
                  </p>
                  {!isSmallScreen ? (
                    <>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Code Here"
                          aria-label="Recipient's username"
                          aria-describedby="button-addon2"
                          onChange={handlePromoChange}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="button"
                            id="button-addon2"
                            onClick={handlePromoSubmit}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                      <div>
                        {promoCodeError && (
                          <p
                            className="summary-container_promo-code-error"
                            style={{
                              marginTop: "-0.75rem",
                              paddingBottom: "0.5rem",
                            }}
                          >
                            {promoCodeError}
                          </p>
                        )}
                      </div>
                    </>
                  ) : (
                    <div style={{ marginBottom: "20px" }}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Code Here"
                        aria-label="Recipient's username"
                        aria-describedby="button-addon2"
                        onChange={handlePromoChange}
                      />
                      {promoCodeError && (
                        <p
                          className="summary-container_promo-code-error"
                          style={{
                            marginTop: "0.25rem",
                            paddingBottom: "0.25rem",
                          }}
                        >
                          {promoCodeError}
                        </p>
                      )}
                      <button
                        className="btn btn-primary mt-1"
                        type="button"
                        id="button-addon2"
                        onClick={handlePromoSubmit}
                      >
                        Apply
                      </button>
                    </div>
                  )}
                </div>
              ) : null}
              <div className="enrollment-container">
                {/* <Row>
                <Col className="ps-0 col-9">
                  <span className="summary-container_enrollment-fee">
                    Subtotal
                  </span>
                </Col>
                <Col className="pe-0 col-3 price-amount">
                  <div className="summary-container_enrollment-price">
                    {uiSummary?.checkout?.subtotal}
                  </div>
                </Col>
              </Row> */}
                {values.eligibility?.isPayroll ? (
                  ""
                ) : (
                  <Row>
                    <Col className="ps-0 col-9">
                      <span className="summary-container_enrollment-fee">
                        Taxes
                      </span>
                    </Col>
                    <Col className="pe-0 col-3 price-amount">
                      <div className="summary-container_enrollment-price">
                        {uiSummary?.checkout?.taxes}
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
              <div className="enrollment-container">
                {values.eligibility?.isPayroll ? (
                  ""
                ) : (
                  <Row className="summary-container__total-due-now">
                    <Col className="ps-0 col-9">
                      <div>TOTAL DUE NOW</div>
                    </Col>
                    <Col className="pe-0 col-3 price-amount">
                      <div className="summary-container_enrollment-price">
                        {uiSummary?.checkout?.totalDueNow}
                      </div>
                    </Col>
                  </Row>
                )}

                <hr />
                <Row className="monthly-total-row">
                  <Col className="ps-0 col-9">
                    <div className="summary-container_enrollment-fee">
                      {values.eligibility?.isPayroll
                        ? "TOTAL PER PAYCHECK"
                        : "MONTHLY TOTAL"}
                    </div>
                    {showStartDate && (
                      <div>
                        <span className="payment-start-date">
                          <small>
                            Starting {uiSummary?.checkout?.startDate}
                          </small>
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col className="pe-0 col-3 price-amount">
                    <div className="summary-container_enrollment-price">
                      {uiSummary?.checkout?.monthlyTotalAfterDiscountWithTax}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Collapse>
      </Card.Body>
    </Card>
  );
};
export default Summary;
