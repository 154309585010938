import { createClient } from "contentful";
import {
  IBrandingFields,
  ICardPlaceholderFields,
  IFaqSectionFields,
  IFeatureFields,
  IIssuesListFields,
  ILegalFields,
  ILinkFields,
  IPayrollAttestationsListFields,
  IProgramAgreementFields,
  IPromoBannerFields,
  IStillHaveQuestionsFields,
  ITabPaneFields,
  ITermsOfUseFields,
} from "../@types/generated/contentful";
import { contentfulConfig } from "../client";
import { DynamicConfig } from "../configuration/config";
import { UserContext } from "../context/UserProvider";
import { useContext } from "react";

const useContentful = (config: DynamicConfig) => {
  const { isContentfulPreviewOn } = useContext(UserContext);
  const client = createClient({
    space: config.contentfulSpace,
    accessToken: isContentfulPreviewOn
      ? config.contentfulPreviewAccessToken
      : config.contentfulAccessToken,
    environment: config.contentfulEnvironment,
    host: isContentfulPreviewOn
      ? "preview.contentful.com"
      : "cdn.contentful.com", //This allows the mock panel to switch the app to see the contentful preview data.
  });

  const getFeatureFields = async () => {
    try {
      const entries = await client.getEntries<IFeatureFields>({
        content_type: "feature",
        links_to_entry: contentfulConfig.ids.landing,
        order: "fields.priority",
        include: 10,
      });
      return entries;
    } catch (error) {
      console.log(error);
    }
  };

  const getFeatureFieldsPayroll = async () => {
    try {
      const entries = await client.getEntries<IFeatureFields>({
        content_type: "feature",
        links_to_entry: contentfulConfig.ids.landing,
        order: "fields.priority",
        include: 10,
      });
      return entries;
    } catch (error) {
      console.log(error);
    }
  };

  const getPhoneAndHours = async () => {
    try {
      const entry = await client.getEntries<IIssuesListFields>({
        content_type: "issuesList",
        links_to_entry: contentfulConfig.ids.help,
        include: 10,
      });
      return entry;
    } catch (error) {
      console.log(error);
    }
  };

  const getBranding = async () => {
    try {
      const entry = await client.getEntries<IBrandingFields>({
        content_type: "branding",
        limit: 1,
      });
      return entry;
    } catch (error) {
      console.log(error);
    }
  };
  const getLegalDisclaimers = async () => {
    try {
      const entry = await client.getEntries<ILegalFields>({
        content_type: "legal",
        links_to_entry: contentfulConfig.ids.landing,
        include: 10,
      });
      return entry;
    } catch (error) {
      console.log(error);
    }
  };

  const getPhoneLink = async () => {
    try {
      const entry = await client.getEntries<ILinkFields>({
        content_type: "link",
      });
      return entry;
    } catch (error) {
      console.log(error);
    }
  };

  const getCardPlaceHolder = async () => {
    try {
      const entry = await client.getEntries<ICardPlaceholderFields>({
        content_type: "cardPlaceholder",
        include: 10,
      });
      return entry;
    } catch (error) {
      console.log(error);
    }
  };

  const getProgramAgreement = async () => {
    try {
      const entry = await client.getEntries<IProgramAgreementFields>({
        content_type: "programAgreement",
        include: 10,
      });
      return entry;
    } catch (error) {
      console.log(error);
    }
  };

  const getPromoBanner = async () => {
    try {
      const entry = await client.getEntries<IPromoBannerFields>({
        content_type: "promoBanner",
        include: 10,
      });
      return entry;
    } catch (error) {
      console.log(error);
    }
  };

  const getFAQs = async () => {
    try {
      const entry = await client.getEntries<IFaqSectionFields>({
        content_type: "faqSection",
        links_to_entry: contentfulConfig.ids.faq,
        order: "fields.priority",
        include: 10,
      });
      return entry;
    } catch (error) {
      console.log(error);
    }
  };

  const getStillHaveQuestions = async () => {
    try {
      const entry = await client.getEntries<IStillHaveQuestionsFields>({
        content_type: "stillHaveQuestions",
        links_to_entry: contentfulConfig.ids.faq,
        include: 1,
      });
      return entry;
    } catch (error) {
      console.log(error);
    }
  };

  const getHowItWorksFeatures = async () => {
    try {
      const entry = await client.getEntries<IFeatureFields>({
        content_type: "feature",
        links_to_entry: contentfulConfig.ids.howItWorks,
        order: "fields.priority",
        include: 10,
      });
      return entry;
    } catch (error) {
      console.log(error);
    }
  };

  const getHowItWorksTabPanes = async () => {
    try {
      const entry = await client.getEntries<ITabPaneFields>({
        content_type: "tabPane",
        links_to_entry: contentfulConfig.ids.howItWorks,
        order: "fields.priority",
        include: 10,
      });
      return entry;
    } catch (error) {
      console.log(error);
    }
  };

  const getHelpList = async () => {
    try {
      const entry = await client.getEntries<IIssuesListFields>({
        content_type: "issuesList",
        links_to_entry: contentfulConfig.ids.help,
        include: 10,
      });
      return entry;
    } catch (error) {
      console.log(error);
    }
  };

  const getLegalDocs = async (id: string) => {
    try {
      const entry = await client.getEntry<ITermsOfUseFields>(id, {
        include: 10,
      });
      return entry;
    } catch (error) {
      console.log(error);
    }
  };

  const getAttetestions = async (id: string) => {
    try {
      const entry = await client.getEntry<IPayrollAttestationsListFields>(id, {
        include: 10,
      });
      return entry;
    } catch (error) {
      console.log(error);
    }
  };

  const getContentById = async <T>(id: string) => {
    try {
      const content = await client.getEntry<T>(id, {
        include: 10,
      });
      return content;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    getFAQs,
    getHelpList,
    getBranding,
    getPhoneLink,
    getLegalDocs,
    getContentById,
    getPromoBanner,
    getAttetestions,
    getFeatureFields,
    getFeatureFieldsPayroll,
    getPhoneAndHours,
    getCardPlaceHolder,
    getLegalDisclaimers,
    getProgramAgreement,
    getStillHaveQuestions,
    getHowItWorksFeatures,
    getHowItWorksTabPanes,
  };
};
export default useContentful;
