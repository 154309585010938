import { PROGRAM_CODE } from "../context/UserProvider";

const getProgramCode = (client) => {
    switch (client) {
        case "walmart" :
            return PROGRAM_CODE.PrimeLTE;
        case "hcsc" :
            return PROGRAM_CODE.PrimeSTD;
      default:
        return PROGRAM_CODE.PrimeSTD;
    }
  };
  
  export default getProgramCode;
  