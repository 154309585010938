import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { FormikWizardState, IEligibilityFormProfileWalmart } from "../types";
import React, { useContext, useEffect, useState } from "react";
import {
  USER_STATUS,
  ELIGIBILITY_MESSAGE,
  USER_PAYROLL_STATUS,
  UserContext,
} from "../../../context/UserProvider";
import { getIn, useFormikContext } from "formik";

import FooterCard from "../components/FooterCard";
import Header from "../components/Header";
import StatusModal from "../components/StatusModal";
import axiosInstance from "../../../axios.instance";
import { ImpersonationContext } from "../../../context/ImpersonationProvider";
import useRouteQuery from "../../../hooks/useRouteQuery";
import moment from "moment";
import { useAuthToken } from "../../shared/Auth0TokenProvider";
import { AccountContext } from "../../../context/AccountProvider";

const Eligibility = ({ next }: { next?: () => void }) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    setFieldTouched,
  } = useFormikContext<FormikWizardState<IEligibilityFormProfileWalmart>>();

  const { user, setUser } = useContext(UserContext);
  const { member } = useContext(AccountContext);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [cardOneRef] = useState<HTMLDivElement | null>(null);
  const [, setRefs] = useState<(HTMLDivElement | null)[]>([]);
  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [impersonateLockForm, setImpersonateLockForm] =
    useState<boolean>(false);
  const { isImpersonated } = useContext(ImpersonationContext);
  const query = useRouteQuery();
  const [isDependentToPrimary, setIsDependentToPrimary] = useState(false);

  const token = useAuthToken();

  useEffect(() => {
    setRefs([cardOneRef]);
  }, [cardOneRef]);

  useEffect(() => {
    if (query.get("isSSO") === "true") {
      setUser({ isSSO: true });
    }
  }, []);
  //set impersonate value from browser parameter
  useEffect(() => {
    setImpersonateLockForm(isImpersonated && window.location.search.length > 0);
    if (isImpersonated && query.get("firstName")) {
      const dob = new Date(query.get("dob") || 0);
      const year = dob.getFullYear();
      const month = ("0" + (dob.getMonth() + 1)).slice(-2).toString();
      const day = ("0" + dob.getDate()).slice(-2).toString();
      setFieldValue("eligibility.firstName", query.get("firstName"));
      setFieldValue("eligibility.lastName", query.get("lastName"));
      setFieldValue("eligibility.year", year);
      setFieldValue("eligibility.day", day);
      setFieldValue("eligibility.month", month);
      setFieldValue(
        "eligibility.associateIdentificationNumber",
        query.get("subscriberId")
      );
      setFieldValue("eligibility.email", query.get("email"));
      setFieldValue("eligibility.subscriberId", query.get("subscriberId"));
      setFieldValue("eligibility.zip", query.get("zip"));

      setFieldTouched("eligibility.name", true);
    } else if (user.isSSO) {
      moment(query.get("dob")).format();
      const year = moment(query.get("dob")).year();
      const month = moment(query.get("dob")).month() + 1;
      const day = moment(query.get("dob")).date();
      setFieldValue(
        "eligibility.firstName",
        query.get("firstName") ? query.get("firstName") : ""
      );
      setFieldValue(
        "eligibility.lastName",
        query.get("lastName") ? query.get("lastName") : ""
      );
      setFieldValue("eligibility.year", query.get("dob") !== "" ? year : "");
      setFieldValue(
        "eligibility.day",
        query.get("dob") !== "" ? (day < 10 ? `0${day}` : day) : ""
      );
      setFieldValue(
        "eligibility.month",
        query.get("dob") !== "" ? (month < 10 ? `0${month}` : month) : ""
      );
      setFieldValue(
        "eligibility.associateIdentificationNumber",
        query.get("subscriberId") ? query.get("subscriberId") : ""
      );
      setFieldValue(
        "eligibility.email",
        query.get("email") ? query.get("email") : ""
      );
      setFieldValue(
        "eligibility.subscriberId",
        query.get("subscriberId") ? query.get("subscriberId") : ""
      );
      setFieldValue(
        "eligibility.zip",
        query.get("zip") !== "" ? query.get("zip") : ""
      );
    } else if (
      member?.eligibilities[0]?.currentSubscriptionStatus === "Cancelled" &&
      !member?.eligibilities[0]?.isPrimary
    ) {
      const { firstName, lastName, dateOfBirth, email, postalCode } = member;

      setFieldValue("eligibility.firstName", firstName);
      setFieldValue("eligibility.lastName", lastName);
      setFieldValue("eligibility.month", dateOfBirth.substring(5, 7));
      setFieldValue("eligibility.day", dateOfBirth.substring(8, 10));
      setFieldValue("eligibility.year", dateOfBirth.substring(0, 4));
      setFieldValue("eligibility.email", email);
      setFieldValue("eligibility.zip", postalCode);
      setIsDependentToPrimary(true);
    }
  }, [user.isSSO, isImpersonated]);

  useEffect(() => {
    if (values.eligibility?.completed && next) {
      next();
    }
  }, []);

  const lockSSOField = (field) => {
    if (user.isSSO) {
      return query.get(field) !== "";
    }
    return false;
  };

  useEffect(() => {
    if (status && intervalId) {
      if (progress >= 100) {
        setProgress(100);
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
    () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [progress, status]);

  const getAge = (dateString: string) => {
    const today = new Date(),
      birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const isOver18 = () => {
    if (values.eligibility && values.eligibility.year) {
      const age = getAge(
        `${values.eligibility?.month}/${values.eligibility?.day}/${values.eligibility?.year}`
      );
      if (!isNaN(age) && age >= 18) return true;
      return false;
    }
  };

  const handleCheckEligibility = async () => {
    setProgress(0);
    setIntervalId(setInterval(() => setProgress((p) => p + 5), 700));
    const vals = values.eligibility!;
    const eligibilityEndpoint = isDependentToPrimary
      ? "/client-eligibility"
      : "/eligibility";
    setStatus("loading");
    setMessage("We're confirming your information");

    axiosInstance
      .post(
        eligibilityEndpoint,
        {
          firstName: vals.firstName,
          lastName: vals.lastName,
          dateOfBirth: `${vals.year}-${vals.month}-${vals.day}`,
          email: vals.email,
          zip: vals.zip,
          groupId: vals.associateIdentificationNumber,
          subscriberId: vals.associateIdentificationNumber,
          isDependent: false,
          createLogin: true,
          clientSpecificData: {
            clientCode: "walmart",
          },
        },
        isDependentToPrimary
          ? {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          : {}
      )
      .then((response) => {
        switch (response.data?.message) {
          case ELIGIBILITY_MESSAGE.Eligible:
            setStatus("CONFIRMED");
            setFieldValue("eligibility.completed", true);
            setFieldValue("eligibility.consumerId", response.data.consumerId);
            setFieldValue(
              "eligibility.isPayroll",
              response.data.clientSpecificData.payrollStatus ===
                USER_PAYROLL_STATUS.Payroll
            );

            setFieldValue(
              "eligibility.payrollFrequency",
              response.data.clientSpecificData.payrollFrequency
            );
            setFieldValue(
              "eligibility.clientStateCode",
              response.data.clientSpecificData.clientStateCode
            );
            setFieldValue(
              "eligibility.clientCode",
              response.data.clientSpecificData.clientCode
            );
            setFieldValue(
              "eligibility.clientMemberId",
              vals.associateIdentificationNumber
            );

            setUser({
              status: USER_STATUS.ELIGIBLE,
              isPayroll:
                response.data.clientSpecificData.payrollStatus ===
                USER_PAYROLL_STATUS.Payroll,
              payrollFrequency:
                response.data.clientSpecificData.payrollStatus.payrollFrequency,
            });
            break;
          // case ELIGIBILITY_MESSAGE.PersonIdFound:
          //   break;
          case ELIGIBILITY_MESSAGE.NotEligible:
            setStatus("DENIED");
            setMessage(
              "The information you provided could not be confirmed as eligible. Please review your information, and try again."
            );
            break;
          case ELIGIBILITY_MESSAGE.EmailUnavailable:
            setStatus("NOEMAIL");
            setMessage(
              "The email address you entered is already in use. Please use a different email address for your account creation."
            );
            break;
          case ELIGIBILITY_MESSAGE.LoginCreated:
            setStatus(ELIGIBILITY_MESSAGE.LoginCreated);
            break;
          case ELIGIBILITY_MESSAGE.ExistingLoginFound:
            setStatus("EXISTS");
            setMessage(
              "Our Records indicate that you already have an account with us. Please login or reset your password to access your account."
            );
            break;
        }
      })
      .catch((error) => {
        console.log(error.toJSON());
        setMessage(
          "The information you provided could not be confirmed as eligible. Please review your information, and try again."
        );
        setStatus("DENIED");
      });
  };

  const handleHandoff = () => {
    if (next) {
      next();
    }
  };

  const scrollTo = (elementName) => {
    const el = document.getElementsByName(elementName);
    el[0].scrollIntoView();
  };

  return (
    <div className="eligibility-form-container">
      <StatusModal
        show={status !== ""}
        status={status}
        message={message}
        onCancel={() => setStatus("")}
        onNext={handleHandoff}
        progress={progress}
      />
      <Header
        title={
          user.isSSO ? `Confirm your information` : `Check your eligibility`
        }
        subtitle={
          !user.isSSO
            ? `Please fill out the information below to see if you're eligible`
            : ""
        }
      />
      <Card
        className="eligibility-form"
        style={{ padding: "24px", marginTop: 20 }}
      >
        <Card.Body style={{ padding: "24px" }}>
          <Card.Title style={{ color: "black" }}>PERSONAL INFO</Card.Title>
          <p style={{ marginBottom: "5px" }}>
            We will use the info below to authenticate your account
          </p>
          <Row className="eligibility-form__row">
            <Form.Group
              as={Col}
              controlId="firstName"
              xs={12}
              md={4}
              lg={3}
              className="mb-3"
            >
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                First Name<span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                maxLength={35}
                name="eligibility.firstName"
                value={values.eligibility!.firstName}
                disabled={
                  lockSSOField("firstName") ||
                  impersonateLockForm ||
                  isDependentToPrimary
                }
                isInvalid={
                  getIn(touched, "eligibility.firstName") &&
                  getIn(errors, "eligibility.firstName")
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "eligibility.firstName")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="lastName" xs={12} md={4} lg={3}>
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                Last Name<span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                maxLength={60}
                name="eligibility.lastName"
                value={values.eligibility!.lastName}
                disabled={
                  lockSSOField("lastName") ||
                  impersonateLockForm ||
                  isDependentToPrimary
                }
                isInvalid={
                  getIn(touched, "eligibility.lastName") &&
                  getIn(errors, "eligibility.lastName")
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "eligibility.lastName")}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Label className="form-control-sm col-form-label p-0 eligibility-form__label">
            Date of Birth <span className="required-field-marker">*</span>
          </Form.Label>
          <Row className="eligibility-form__row">
            <Form.Group
              as={Col}
              controlId="month"
              xs={3}
              sm={2}
              md={1}
              className="mb-3"
            >
              <Form.Label className="form-control-sm col-form-label p-0">
                Month
              </Form.Label>
              <Form.Control
                type="text"
                name="eligibility.month"
                placeholder="MM"
                value={values.eligibility!.month}
                disabled={
                  lockSSOField("dob") ||
                  impersonateLockForm ||
                  isDependentToPrimary
                }
                isInvalid={
                  (getIn(touched, "eligibility.month") &&
                    getIn(errors, "eligibility.month")) ||
                  (getIn(touched, "eligibility.month") &&
                    values.eligibility?.month.length !== 2) ||
                  (getIn(touched, "eligibility.month") &&
                    isNaN(Number(values.eligibility?.month)))
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="day" xs={3} sm={2} md={1}>
              <Form.Label className="form-control-sm col-form-label p-0">
                Day
              </Form.Label>
              <Form.Control
                type="text"
                name="eligibility.day"
                placeholder="DD"
                value={values.eligibility!.day}
                disabled={
                  lockSSOField("dob") ||
                  impersonateLockForm ||
                  isDependentToPrimary
                }
                isInvalid={
                  (getIn(touched, "eligibility.day") &&
                    getIn(errors, "eligibility.day")) ||
                  (getIn(touched, "eligibility.day") &&
                    values.eligibility?.day.length !== 2) ||
                  (getIn(touched, "eligibility.day") &&
                    isNaN(Number(values.eligibility?.day)))
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="year" xs={4} md={2}>
              <Form.Label className="form-control-sm col-form-label p-0 pb-0">
                Year
              </Form.Label>
              <Form.Control
                type="text"
                name="eligibility.year"
                value={values.eligibility!.year}
                disabled={
                  lockSSOField("dob") ||
                  impersonateLockForm ||
                  isDependentToPrimary
                }
                isInvalid={
                  (getIn(touched, "eligibility.year") &&
                    getIn(errors, "eligibility.year")) ||
                  (getIn(touched, "eligibility.year") &&
                    values.eligibility?.year.length !== 4) ||
                  (getIn(touched, "eligibility.year") &&
                    isNaN(Number(values.eligibility?.year))) ||
                  (getIn(touched, "eligibility.year") && !isOver18())
                }
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="YYYY"
              />
            </Form.Group>
          </Row>
          {getIn(touched, "eligibility.year") && !isOver18() && (
            <div className="invalid-feedback-dob">
              Must be 18 years or older.
            </div>
          )}
          <Row className="eligibility-form__row">
            <Form.Group as={Col} xs={12} sm={9} md={5} className="mb-3">
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                {values.eligibility!.hasBlue365 === "true"
                  ? "Blue365 Email Address"
                  : "Email Address"}
                <span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="eligibility.email"
                value={values.eligibility!.email}
                isInvalid={
                  getIn(touched, "eligibility.email") &&
                  getIn(errors, "eligibility.email")
                }
                disabled={lockSSOField("email") || isDependentToPrimary}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "eligibility.email")}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="eligibility-form__row">
            <Form.Group as={Col} xs={9} sm={5} md={3} className="mb-3">
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                ZIP Code<span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="eligibility.zip"
                value={values.eligibility!.zip}
                isInvalid={
                  getIn(touched, "eligibility.zip") &&
                  getIn(errors, "eligibility.zip")
                }
                disabled={lockSSOField("zip")}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={() => scrollTo("eligibility.zip")}
              />
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "eligibility.zip")}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="eligibility-form__row">
            <Form.Group
              className="mb-3"
              controlId="formBasicCheckboxPrivacyPolicy"
            >
              <Row className="eligibility-form__row">
                <Form.Control.Feedback type="invalid">
                  {getIn(errors, "eligibility.isDependent")}
                </Form.Control.Feedback>
              </Row>
            </Form.Group>
          </Row>
          {values.eligibility!.isDependent ? null : (
            <>
              <Card.Title style={{ color: "black" }}>ASSOCIATE INFO</Card.Title>
              <p style={{ marginBottom: "5px" }}>
                We will use the info to determine your payment options at
                checkout
              </p>{" "}
            </>
          )}
          {values.eligibility!.isDependent ? null : (
            <Row className="eligibility-form__row">
              <Form.Group as={Col} xs={7} sm={6} md={5} lg={4} className="mb-3">
                <Form.Label className="form-control-sm col-form-label p-0 eligibility-form__label">
                  Walmart Identification Number
                  <span className="required-field-marker">*</span>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="eligibility.associateIdentificationNumber"
                    value={values.eligibility!.associateIdentificationNumber}
                    disabled={
                      lockSSOField("subscriberId") || impersonateLockForm
                    }
                    isInvalid={
                      getIn(
                        touched,
                        "eligibility.associateIdentificationNumber"
                      ) &&
                      getIn(errors, "eligibility.associateIdentificationNumber")
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={() =>
                      scrollTo("eligibility.associateIdentificationNumber")
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {getIn(errors, "eligibility.associateIdentificationNumber")}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Row>
          )}

          <Row className="eligibility-form__row">
            <Form.Group
              className="mb-3"
              controlId="formBasicCheckboxPrivacyPolicy"
            >
              <Row className="eligibility-form__row">
                <Col xs={1} style={{ width: "3%", paddingTop: "4px" }}>
                  <Form.Check
                    id="privacy"
                    value="true"
                    isInvalid={
                      getIn(touched, "eligibility.privacyPolicy") &&
                      getIn(errors, "eligibility.privacyPolicy")
                    }
                    name="eligibility.privacyPolicy"
                    checked={values.eligibility!.privacyPolicy === true}
                    inline
                    style={{ paddingLeft: 0 }}
                    onChange={handleChange}
                  />
                </Col>
                <Col xs={8} style={{ padding: 0, margin: 0 }}>
                  <span style={{ fontSize: "0.8rem" }}>
                    <span className="required-field-marker">*</span>By providing
                    your email address and/or any other personal information, as
                    defined under applicable law, you acknowledge that you are
                    agreeing to our use of your information as provided in our{" "}
                    <a
                      href="/terms"
                      target="_blank"
                      rel="noreferrer"
                      className="a-link"
                    >
                      Terms of Use
                    </a>{" "}
                    and{" "}
                    <a
                      href="/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                      className="a-link"
                    >
                      Privacy Policy
                    </a>
                  </span>
                </Col>
              </Row>
              <Row className="eligibility-form__row">
                <Form.Control.Feedback type="invalid">
                  {getIn(errors, "eligibility.privacyPolicy")}
                </Form.Control.Feedback>
              </Row>
            </Form.Group>
          </Row>
          <Row className="eligibility-form__row">
            <Form.Group
              className="mb-3"
              controlId="formBasicCheckboxContactInfo"
            >
              <Row className="eligibility-form__row">
                <Form.Control.Feedback type="invalid">
                  {getIn(errors, "eligibility.contactInfo")}
                </Form.Control.Feedback>
              </Row>
            </Form.Group>
          </Row>
        </Card.Body>
      </Card>
      <FooterCard>
        <Container>
          <Row>
            <Col sm={{ span: 3, offset: 5 }}></Col>
            <Col sm={3}>
              <Button
                variant="primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap", width: "100%" }}
                onClick={handleCheckEligibility}
                disabled={
                  user.isSSO
                    ? Boolean(errors.eligibility !== undefined) ||
                      values.eligibility!.month.length < 2 ||
                      values.eligibility!.day.length < 2 ||
                      values.eligibility!.year.length < 4 ||
                      !isOver18() ||
                      values.eligibility!.privacyPolicy !== true
                    : Object.keys(touched).length === 0
                    ? true
                    : Boolean(errors.eligibility !== undefined) ||
                      values.eligibility!.month.length < 2 ||
                      values.eligibility!.day.length < 2 ||
                      values.eligibility!.year.length < 4 ||
                      !isOver18() ||
                      values.eligibility!.privacyPolicy !== true
                }
              >
                Check My Eligibility
              </Button>
            </Col>
          </Row>
        </Container>
      </FooterCard>
    </div>
  );
};

export default Eligibility;
