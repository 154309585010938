import React, { Fragment } from "react";
import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES, Block, Inline } from "@contentful/rich-text-types";

import {
  Branding,
  FAQQuestion,
  FAQSection,
  FAQStillHaveQuestions,
  FeatureAttribute,
  FeatureBody,
  FeatureBottomBody,
  FeatureComponentBody,
  FeatureDefaultBody,
  FeatureDigitalBody,
  FeatureInput,
  FeatureMembershipBody,
  FeatureMobileBody,
  FeatureTestimonialBody,
  Issue,
  IssueList,
  ProgramAgreementType,
  ProgramAgreementSection,
  TermsOfUse,
  TermsOfUseSection,
  TermsTableRow,
  CustomModal,
} from "../types";
import Link from "../components/contentful/Link";
import Tabs from "../components/contentful/Tabs";
import PackageSelect from "../components/contentful/PackageSelect";
import TableComponent from "../components/contentful/TableComponent";
import TermsTable from "../components/contentful/TermsTable";
import { Entry } from "contentful";
import {
  IAttribute,
  IBrandingFields,
  IFaqQuestion,
  IFaqSectionFields,
  IFeature,
  IFeatureBottomBody,
  IFeatureBottomBodyNoButton,
  IFeatureComponentBody,
  IFeatureDefaultBody,
  IFeatureDigitalBody,
  IFeatureFields,
  IFeatureMembershipBody,
  IFeatureMobileAppBody,
  IFeatureTestimonialBody,
  IIssue,
  IIssuesListFields,
  IModalFields,
  IPackageSelectFields,
  IPrivacyTableFields,
  IProgramAgreementFields,
  IProgramAgreementSection,
  IPromoBannerFields,
  IStillHaveQuestionsFields,
  ITableFields,
  ITableRow,
  ITabPaneFields,
  ITermsOfUseFields,
  ITermsSection,
  ITermsTableFields,
} from "../@types/generated/contentful";
import { ReactNode } from "react";
import PrivacyTable from "../components/contentful/PrivacyTable";

//displayname
export default function Contentful() {
  return <Fragment />;
}

function convertToAttribute(attributes: IAttribute[]) {
  const out: FeatureAttribute[] = [];
  attributes.forEach((a) => {
    out.push({
      image: a.fields.image?.fields.file.url ?? "",
      text: documentToReactComponents(a.fields.text!),
    });
  });
  return out;
}
export function convertToFeatureLinked(
  input: IFeature,
  params: { [key: string]: unknown },
  index: number
): FeatureInput {
  const out: FeatureInput = {
    background: input.fields?.background ?? "",
    backgroundColor: input.fields?.backgroundColor ?? "",
    size: input.fields?.size ?? "",
    button: input.fields?.button ?? true,
    buttonText: input.fields?.buttonText ?? "",
    buttonUrl: input.fields?.buttonUrl ?? "",
    left: input.fields?.left ?? true,
    slanted: input.fields?.slanted ?? false,
    media: input.fields?.media?.fields?.file.url ?? "",
    body: input.fields?.body
      ? bodyConversion(input.fields.type, input.fields.body!, params, index)
      : { text: "" },
    featureIndex: index,
    type: input.fields?.type,
    featureClassName: input.fields?.featureClassName ?? "",
  };

  return out;
}
export function convertToFeature(
  input: Entry<IFeatureFields>,
  params: { [key: string]: unknown },
  index: number
): FeatureInput {
  const out: FeatureInput = {
    background: input.fields.background ?? "",
    backgroundColor: input.fields.backgroundColor ?? "",
    size: input.fields.size ?? "",
    button: input.fields.button ?? true,
    buttonText: input.fields.buttonText ?? "",
    buttonUrl: input.fields.buttonUrl ?? "",
    left: input.fields.left ?? true,
    slanted: input.fields.slanted ?? false,
    media: input.fields.media?.fields.file.url ?? "",
    body: input.fields?.body
      ? bodyConversion(input.fields.type, input.fields.body!, params, index)
      : { text: "" },
    featureIndex: index,
    type: input.fields.type,
    featureClassName: input.fields.featureClassName ?? "",
  };

  return out;
}

function bodyConversion(
  type: string,
  body: IFeatureFields["body"],
  params: { [key: string]: unknown },
  index: number
): FeatureBody {
  switch (type) {
    case "feature-default":
      return convertToFeatureDefault(body as IFeatureDefaultBody, params);
    case "feature-default-weekly":
      return convertToFeatureDefault(body as IFeatureDefaultBody, params);
    case "feature-wellness":
      return convertToFeatureDefault(body as IFeatureDefaultBody, params);
    case "feature-membership":
      return convertToFeatureMembership(body as IFeatureMembershipBody);
    case "feature-family-membership":
      return convertToFeatureFamilyMembership(
        body as IFeatureDefaultBody,
        params
      );
    case "feature-digital":
      return convertToFeatureDigital(
        body as IFeatureDigitalBody,
        params,
        index
      );
    case "feature-digital-burnalong":
      return convertToFeatureDigital(
        body as IFeatureDigitalBody,
        params,
        index
      );
    case "feature-membership-cards":
      return convertToFeatureDigital(
        body as IFeatureDigitalBody,
        params,
        index
      );
    case "feature-mobile-app":
      return convertToFeatureMobile(body as IFeatureMobileAppBody);
    case "feature-testimonial":
      return convertToFeatureTestimonial(
        body as IFeatureTestimonialBody,
        params
      );
    case "feature-bottom":
      return convertToFeatureBottom(body as IFeatureBottomBody);
    case "feature-bottom-no-button":
      return convertToFeatureBottom(body as IFeatureBottomBodyNoButton);
    case "feature-component":
      return convertToFeatureComponent(body as IFeatureComponentBody, params);
    default:
      return { text: "" };
  }
}

function customComponentFactory(
  type: string,
  params: { [key: string]: unknown },
  fields: Record<string, unknown>
): React.ReactElement {
  switch (type) {
    case "link":
      return (
        <Link
          onClick={params.onClick as (e: unknown) => void}
          text={(fields.text as string) ?? ""}
          href={fields.href as string}
        />
      );
    case "tabs":
      return (
        <Tabs
          tabContent={params.tabContent as unknown as Entry<ITabPaneFields>[]}
          onStep={params.onStep as (index: number) => (e: unknown) => void}
          step={params.step as number}
        />
      );
    case "packageSelect":
      return (
        <PackageSelect
          {...params}
          {...(fields as unknown as IPackageSelectFields)}
        />
      );
    case "table":
      return <TableComponent {...(fields as unknown as ITableFields)} />;
    case "termsTable":
      return (
        <TermsTable
          rows={(fields as unknown as ITermsTableFields).tableRows.map(
            (row: ITableRow) => convertToTermsTableRow(row)
          )}
        />
      );
    case "privacyTable":
      return (
        <PrivacyTable
          rows={(fields as unknown as IPrivacyTableFields).tableRows.map(
            (row: ITableRow) => convertToTermsTableRow(row)
          )}
        />
      );
    case "jumpLinkComponent":
      return (
        <a className="anchor" id={fields.identifier as unknown as string} />
      );
    default:
      return <div></div>;
  }
}

export function convertToFeatureComponent(
  input: IFeatureComponentBody,
  params: { [key: string]: unknown }
): FeatureComponentBody {
  const out: FeatureComponentBody = {
    node: customComponentFactory(
      (input.fields.type as string) ?? "",
      params,
      input.fields.component?.fields as Record<string, unknown>
    ),
  };

  return out;
}

export function convertToFeatureDefault(
  input: IFeatureDefaultBody,
  params: { [key: string]: unknown }
): FeatureDefaultBody {
  const renderOptions: Options = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node: Block | Inline): ReactNode => {
        return customComponentFactory(
          node.data?.target?.sys?.contentType?.sys?.id ?? "",
          params,
          node.data?.target?.fields ?? {}
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline): ReactNode => {
        // render the EMBEDDED_ASSET as you need
        return (
          <img
            src={`https://${node.data.target.fields.file.url}`}
            height={node.data.target.fields.file.details.image.height}
            width={node.data.target.fields.file.details.image.width}
            alt="image"
          />
        );
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node: Block | Inline): ReactNode => {
        // render the EMBEDDED_ASSET as you need
        return (
          <a href={node.data.target.fields.href} target="_blank">
            <img
              src={`https://${node.data.target.fields.text}`}
              alt={node.data.target.fields.name}
            />
          </a>
        );
      },
    },
  };
  const out: FeatureDefaultBody = {
    text:
      (
        <div className="embedded-entry-container">
          {documentToReactComponents(input.fields.text!, renderOptions)}
        </div>
      ) ?? null,
    iconList: input.fields.iconList ?? [],
  };

  return out;
}

export function convertToFeatureDigital(
  input: IFeatureDigitalBody,
  params: { [key: string]: unknown },
  index: number
): FeatureDigitalBody {
  const out: FeatureDigitalBody = {
    header: input.fields.header ?? "",
    subtext: input.fields.subText ?? "",
    buttonText: input.fields.buttonText ?? "",
    features: input.fields.features
      ? input.fields.features.map((f: IFeature): FeatureInput => {
          return convertToFeatureLinked(f, params, index);
        })
      : [],
  };
  return out;
}

export function convertToFeatureFamilyMembership(
  input: IFeatureDefaultBody,
  params: { [key: string]: unknown }
): FeatureDefaultBody {
  const renderOptions: Options = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node: Block | Inline): ReactNode => {
        return customComponentFactory(
          node.data?.target?.sys?.contentType?.sys?.id ?? "",
          params,
          node.data?.target?.fields ?? {}
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline): ReactNode => {
        // render the EMBEDDED_ASSET as you need
        return (
          <img
            src={`https://${node.data.target.fields.file.url}`}
            height={node.data.target.fields.file.details.image.height}
            width={node.data.target.fields.file.details.image.width}
            alt="image"
          />
        );
      },
    },
  };
  const out: FeatureDefaultBody = {
    text: documentToReactComponents(input.fields.text!, renderOptions) ?? null,
    iconList: input.fields.iconList ?? [],
  };

  return out;
}

export function convertToFeatureMobile(
  input: IFeatureMobileAppBody
): FeatureMobileBody {
  const out: FeatureMobileBody = {
    androidLink: input.fields.androidLink ?? "",
    iosLink: input.fields.iosLink ?? "",
    iosIcon: input.fields.iosIcon ? input.fields.iosIcon.fields.file.url : "",
    androidIcon: input.fields.androidIcon
      ? input.fields.androidIcon.fields.file.url
      : "",
    text: documentToReactComponents(input.fields.text!),
  };
  return out;
}

export function convertToFeatureTestimonial(
  input: IFeatureTestimonialBody,
  params: { [key: string]: unknown }
): FeatureTestimonialBody {
  const renderOptions = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node: Block | Inline): ReactNode => {
        return customComponentFactory(
          node.data?.target?.sys?.contentType?.sys?.id ?? "",
          params,
          node.data?.target?.fields ?? {}
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: Inline | Block) => {
        // render the EMBEDDED_ASSET as you need
        return (
          <img
            src={`https://${node.data.target.fields.file.url}`}
            height={node.data.target.fields.file.details.image.height}
            width={node.data.target.fields.file.details.image.width}
            className="large-quote"
            alt="image"
          />
        );
      },
    },
  };
  const out: FeatureTestimonialBody = {
    content:
      documentToReactComponents(input.fields.content!, renderOptions) ?? null,
  };
  return out;
}

export function convertToFeatureBottom(
  input: IFeatureBottomBody | IFeatureBottomBodyNoButton
): FeatureBottomBody {
  const out: FeatureBottomBody | IFeatureBottomBodyNoButton = {
    header: input.fields.header ?? "",
    subText: input.fields.subText ?? "",
  };
  return out;
}

export function convertToBranding(input: Entry<IBrandingFields>): Branding {
  const out: Branding = {
    brandImage: input.fields.brandImage?.fields.file.url ?? "",
    brandingText: input.fields.brandingText ?? "",
  };
  return out;
}

export function convertToModal(input: Entry<IModalFields>): CustomModal {
  // console.log(documentToReactComponents(input.fields.body.fields.content!));
  const out: CustomModal = {
    name: input.fields.name ?? "",
    headerTitle: documentToReactComponents(input.fields.headerTitle!),
    body: {
      name: input.fields.name ?? "",
      content: input.fields.body?.fields.body
        ? documentToReactComponents(input.fields.body.fields.body!)
        : "",
    },
    footerButton: input.fields.footerButton ?? false,
    footerButtonText: input.fields.footerButtonText ?? "",
    footerButtonUrl: input.fields.footerButtonUrl ?? "",
    footerButtonTwo: input.fields.footerButtonTwo ?? false,
    footerButtonTwoText: input.fields.footerButtonTwoText ?? "",
    footerButtonUrlTwo: input.fields.footerButtonUrl ?? "",
    // brandingText: input.fields.brandingText ?? "",
  };
  return out;
}

export function convertToFAQQuestion(
  input: IFaqQuestion,
  params: { [key: string]: unknown }
): FAQQuestion {
  const renderOptions = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node: Block | Inline) => {
        return customComponentFactory(
          node.data?.target?.sys?.contentType?.sys?.id ?? "",
          params,
          node.data?.target?.fields ?? {}
        );
      },
    },
  };
  const out: FAQQuestion = {
    question: input.fields.question ?? "",
    answer: documentToReactComponents(input.fields.answer!, renderOptions),
  };
  return out;
}

export function convertToFAQSection(
  input: Entry<IFaqSectionFields>,
  params: { [key: string]: unknown }
): FAQSection {
  const out: FAQSection = {
    header: input.fields.header ?? "",
    questions: input.fields.questions
      ? input.fields.questions?.map((q: IFaqQuestion) =>
          convertToFAQQuestion(q, params)
        )
      : [],
  };

  return out;
}

export function convertToFAQStillHaveQuestions(
  input: Entry<IStillHaveQuestionsFields>
): FAQStillHaveQuestions {
  const out: FAQStillHaveQuestions = {
    hours: input.fields.hours ?? "",
    phoneNumber: input.fields.phoneNumber ?? "",
    spanishPhoneNumber: input.fields.spanishPhoneNumber ?? "",
  };
  return out;
}

export function convertToTermsTableRow(input: ITableRow): TermsTableRow {
  const renderOptions: Options = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node: Block | Inline): ReactNode => {
        return customComponentFactory(
          node.data?.target?.sys?.contentType?.sys?.id ?? "",
          {},
          node.data?.target?.fields ?? {}
        );
      },
    },
  };
  const out: TermsTableRow = {
    columnOne: documentToReactComponents(
      input.fields.columnOne!,
      renderOptions
    ),
    columnOneSpan: input.fields?.columnOneSpan ?? 1,
    columnTwo: documentToReactComponents(input.fields.columnTwo!),
    columnTwoSpan: input.fields?.columnTwoSpan ?? 1,
    columnThree: documentToReactComponents(input.fields.columnThree!),
    columnThreeSpan: input.fields?.columnThreeSpan ?? 1,
    columnFour: documentToReactComponents(input.fields.columnFour!),
    columnFourSpan: input.fields?.columnFourSpan ?? 1,
  };
  return out;
}

export function convertToTermsSection(input: ITermsSection): TermsOfUseSection {
  const renderOptions = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node: Block | Inline) => {
        return customComponentFactory(
          node.data?.target?.sys?.contentType?.sys?.id ?? "",
          {},
          node.data?.target?.fields ?? {}
        );
      },
    },
    renderText: (text: string) => {
      return text.split("\n").reduce(
        (
          children: ReactNode[][],
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          textSegment: any,
          index: number
        ) => {
          return [...children, index > 0 && <br key={index} />, textSegment];
        },
        []
      );
    },
  };

  const out: TermsOfUseSection = {
    header: input.fields?.header ?? "",
    content: documentToReactComponents(input.fields.content!, renderOptions),
  };
  return out;
}

export function convertToTerms(input: Entry<ITermsOfUseFields>): TermsOfUse {
  const out: TermsOfUse = {
    lastUpdated: input.fields?.lastUpdated ?? "",
    sections: input.fields.sections
      ? input.fields.sections.map((section: ITermsSection) => {
          return convertToTermsSection(section);
        })
      : [],
  };
  return out;
}

export function convertToAgreementSection(
  input: IProgramAgreementSection
): ProgramAgreementSection {
  const renderOptions = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node: Block | Inline) => {
        return customComponentFactory(
          node.data?.target?.sys?.contentType?.sys?.id ?? "",
          {},
          node.data?.target?.fields ?? {}
        );
      },
    },
  };

  const out: TermsOfUseSection = {
    header: input.fields?.header ?? "",
    content: documentToReactComponents(input.fields.content!, renderOptions),
  };
  return out;
}

export function convertToAgreement(
  input: Entry<IProgramAgreementFields>
): ProgramAgreementType {
  const out: ProgramAgreementType = {
    updated: input.fields?.updated ?? "",
    sections: input.fields.sections
      ? input.fields.sections.map((section: IProgramAgreementSection) => {
          return convertToAgreementSection(section);
        })
      : [],
  };
  return out;
}

export function convertToIssueList(input: Entry<IIssuesListFields>): IssueList {
  const out: IssueList = {
    phoneNumber: input.fields.phoneNumber,
    hours: input.fields.hours,
    spanishPhoneNumber: input.fields.spanishPhoneNumber,
  };
  return out;
}

export function convertToIssuesList(input: Entry<IIssuesListFields>): Issue[] {
  const out: Issue[] =
    input.fields.issues?.map((v: IIssue) => {
      return {
        information: documentToReactComponents(v.fields.information),
        informationLabel: v.fields.informationLabel,
        value: v.fields.value,
        label: v.fields.label,
      };
    }) ?? [];
  return out;
}

export function convertToPromoBanner(
  input: IPromoBannerFields
): IPromoBannerFields {
  const out: IPromoBannerFields = {
    name: input.name ?? "",
    description: input.description ?? "",
  };
  return out;
}

export function convertToFeatureMembership(
  input: IFeatureMembershipBody
): FeatureMembershipBody {
  const out: FeatureMembershipBody = {
    callout: documentToReactComponents(input.fields.callout!),
    header: input.fields.header ?? "",
    imageOverlayColor: input.fields.imageOverlayColor ?? "",
    attributes: convertToAttribute(input.fields.attributes!),
  };

  return out;
}
