import React, { Fragment, useContext, useState } from "react";
import { Button, Col, Form, Modal, ProgressBar, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import SomethingWentWrong from "../../../pages/SomethingWentWrong";
import { useAuth0 } from "@auth0/auth0-react";
import { ELIGIBILITY_MESSAGE } from "../../../context/UserProvider";
import ModalMapHCSC from "../../../resources/ModalMapHCSC.svg";
import ModalMapWalmart from "../../../resources/ModalMapWalmart.svg";
import ModalMapFYW from "../../../resources/ModalMapFYW.svg";
import ModalMapMW from "../../../resources/ModalMapMW.svg";
import ModalMap from "../../../resources/ModalMap.svg";
import { emailRegex } from "../../../utility/Constants";

import { useConfig } from "../../../configuration/useConfig";
import { UserContext } from "../../../context/UserProvider";
import { getIn, useFormikContext } from "formik";
import {
  FormikWizardState,
  EligibilityFlow,
  IEligibilityFormProfileFYW,
} from "../types";

const StatusModal = ({
  status,
  message,
  show,
  onNext,
  onCancel,
  progress,
  clientCode,
  setEmail,
  handleCheckEligibility,
}: {
  status: string;
  message: string;
  show: boolean;
  onNext: (e: unknown) => void;
  onCancel: (e: unknown) => void;
  progress?: number;
  clientCode?: string;
  email?: string;
  setEmail?: (e: string) => void;
  handleCheckEligibility?: (e: unknown) => void;
}) => {
  const { errors, touched, handleBlur } =
    useFormikContext<FormikWizardState<IEligibilityFormProfileFYW>>();

  const [check, setCheck] = useState(false);
  const [checkPA, setCheckPA] = useState(false);
  const [handlePrivacy, setHandlePrivacy] = useState(false);
  const [handleBCBSAAgreement, setHandleBCBSAAgreement] = useState(false);

  const history = useHistory();

  function isValidEmail(email) {
    return new RegExp(emailRegex).test(email);
  }

  const handleEmailChange = (e) => {
    if (setEmail) {
      setEmail(e.target.value);
    }
  };

  const handleJWTSubmit = (e) => {
    if (handleCheckEligibility) {
      handleCheckEligibility(e);
    }
    onCancel(e);
  };

  const checked = () => {
    setCheck(!check);
  };
  const checkedPA = () => {
    setCheckPA(!checkPA);
  };

  const { loginWithRedirect } = useAuth0();
  const { user } = useContext(UserContext);
  const { config } = useConfig();
  const { values } = useFormikContext<FormikWizardState<EligibilityFlow>>();

  const getContent = () => {
    switch (status.toUpperCase()) {
      case "GENERIC-ERROR":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__exclamation"></div>
              <h2>
                <b>Something Went Wrong</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">
              <p style={{ textAlign: "left" }}>
                It seems we're having trouble processing your request. Please
                try again later.
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="outline-primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap", width: `65%`, height: `50%` }}
                onClick={onCancel}
              >
                Close
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "ERROR-CHECKOUT":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__exclamation"></div>
              <h2>
                <b>Something Went Wrong</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">
              <p style={{ textAlign: "left" }}>
                It seems we&apos;re having trouble completing your membership.
                <br />
                Please <b>do not</b> reattempt checkout at this time.
              </p>
              <br />
              <p style={{ textAlign: "left" }}>
                If you have any questions, please contact customer service at:{" "}
                <a
                  href={`tel:${config["contact.phoneNumber"]}`}
                  className="a-link"
                >
                  {config["contact.phoneNumber"]}
                </a>
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              {/* <Button
                variant="outline-primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap", width: `65%`, height: `50%` }}
                onClick={onCancel}
              >
                Close
              </Button> */}
            </Modal.Footer>
          </Fragment>
        );
      case "ERROR":
        return (
          <Fragment>
            <Modal.Body className="modal-body-form">
              <SomethingWentWrong small={true} />
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="outline-primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap" }}
                onClick={onCancel}
              >
                Close
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "LOADING":
        return (
          <Modal.Body>
            <div className="d-flex p-2 flex-column text-center align-items-center">
              <p
                style={{
                  textAlign: "center",
                }}
              >
                <h3 style={{ fontSize: "24px", color: "#000000" }}>
                  <b>Hang tight!</b>
                </h3>
              </p>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  color: "#333333",
                }}
              >
                {message}
                <br />
                <img
                  src={
                    config["client"] === "hcsc"
                      ? ModalMapHCSC
                      : config["client"] === "walmart"
                      ? ModalMapWalmart
                      : config["client"] === "mutual-of-omaha"
                      ? ModalMapMW
                      : config["client"] === "bcbsa" ||
                        config["client"] === "bsca"
                      ? ModalMapFYW
                      : ModalMap
                  }
                  height="140"
                  width="290"
                  alt="map"
                  className="m-3"
                />
                <br />
                <span> Wrapping up...</span>
                <div className="progress-bar w-100 mt-3 mb-4 ml-3">
                  <ProgressBar striped now={progress} />
                </div>
                <span>
                  Please <b>do not refresh the page.</b>
                </span>
              </p>
            </div>
          </Modal.Body>
        );
      case "CONFIRMED":
        if (clientCode === "walmart" && !values.eligibility?.isPayroll) {
          return (
            <Fragment>
              <Modal.Header className="modal-header-form">
                <div className="modal-header-form__checkmark"></div>
                <h2>
                  <b>Eligibility Confirmed!</b>
                </h2>
              </Modal.Header>
              <Modal.Body className="modal-body-form">
                <h2 style={{ textAlign: "center" }}>
                  <b>Please agree to our terms and continue</b>
                </h2>
                <p>
                  <small>
                    You&apos;re one step closer to a longer, healthier life.
                  </small>
                </p>
                <p style={{ marginBottom: "-20px", marginTop: "20px" }}>
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      onChange={checkedPA}
                      style={{
                        marginRight: "6px",
                        height: "13px",
                        width: "13px",
                      }}
                    />
                    <small className="eligibility-form__row">
                      <span className="required-field-marker">*</span>By
                      checking this box, I acknowledge that I have read and
                      agree to comply with the terms and conditions of the{" "}
                      <a
                        href="/fitness-program-agreement"
                        target="_blank"
                        rel="noreferrer"
                        className="a-link"
                      >
                        Fitness Pass Program Agreement.
                      </a>{" "}
                    </small>
                  </label>
                </p>
              </Modal.Body>
              <Modal.Footer className="modal-footer-form">
                <Button
                  bsPrefix="btn font-weight-bold modal-footer-form__button"
                  disabled={!checkPA}
                  onClick={onNext}
                >
                  Agree & Continue
                </Button>
              </Modal.Footer>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Modal.Header className="modal-header-form">
                <div className="modal-header-form__checkmark"></div>
                <h2>
                  <b>Eligibility Confirmed!</b>
                </h2>
              </Modal.Header>
              <Modal.Body className="modal-body-form">
                <div
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                    color: "#333333",
                    fontWeight: "bold",
                  }}
                >
                  Congratulations! Your eligibility has been confirmed!
                </div>
                <p>You&apos;re one step closer to a longer, healthier life.</p>
              </Modal.Body>
              <Modal.Footer className="modal-footer-form">
                <Button
                  bsPrefix="btn font-weight-bold modal-footer-form__button"
                  onClick={onNext}
                >
                  Proceed to Next Step
                </Button>
              </Modal.Footer>
            </Fragment>
          );
        }

      case "DENIED":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__exclamation"></div>
              <h2>
                <b>Eligibility Cannot Be Confirmed</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">{message}</Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                bsPrefix="btn font-weight-bold modal-footer-form__close_button"
                style={{ whiteSpace: "nowrap" }}
                onClick={onCancel}
              >
                Close
              </Button>
              <Button
                bsPrefix="btn font-weight-bold modal-footer-form__button"
                style={{ whiteSpace: "nowrap" }}
                onClick={onCancel}
              >
                Re-Enter Information
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case ELIGIBILITY_MESSAGE.LoginCreated.toUpperCase():
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__checkmark"></div>
              <h2>
                <b>Eligibility Confirmed!</b>
              </h2>
            </Modal.Header>
            <Modal.Body
              className="modal-body-form"
              style={{ textAlign: "center" }}
            >
              <b>
                Congratulations! Your eligibility has been confirmed and your
                portal account was created!
              </b>
              <br />
              <br />
              Instructions for your account setup have been emailed to you.
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                bsPrefix="btn font-weight-bold modal-footer-form__close_button"
                style={{ whiteSpace: "nowrap" }}
                onClick={onCancel}
              >
                Close
              </Button>
              <Button
                bsPrefix="btn font-weight-bold modal-footer-form__button"
                onClick={() =>
                  user.isSSO ? history.push("/sso") : loginWithRedirect()
                }
                style={{ whiteSpace: "nowrap" }}
              >
                {user.isSSO ? "Go to Account Dashboard" : "Go to Login"}
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case ELIGIBILITY_MESSAGE.IsSSO.toUpperCase():
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__checkmark"></div>
              <h2>
                <b>Eligibility Confirmed!</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">
              <h6>
                There's a NEW and faster way to access {config.programName}
              </h6>
              <br></br>
              <p>
                <small>
                  Instructions for your account setup have been emailed to you.
                </small>
              </p>
              <br></br>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                bsPrefix="btn font-weight-bold modal-footer-form__button"
                style={{ width: `66%` }}
                onClick={() =>
                  user.isSSO ? history.push("/account") : loginWithRedirect()
                }
              >
                {"Go To Dashboard"}
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case ELIGIBILITY_MESSAGE.JWTSSO.toUpperCase():
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__checkmark"></div>
              <h2>
                <b
                  style={{
                    fontSize: "24px",
                    color: "#FFFFFF",
                    fontWeight: "bold",
                  }}
                >
                  Terms & Email Confirmation
                </b>
              </h2>
            </Modal.Header>
            <Modal.Body
              className="modal-body-form"
              style={{ paddingTop: "30px", paddingBottom: "0px" }}
            >
              <h5 style={{ marginBottom: "10px" }}>
                <b
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#000000",
                  }}
                >
                  Please confirm or update your email
                </b>
              </h5>
              <Row className="eligibility-form__row">
                <Form.Group as={Col} xs={5} sm={5} md={7}>
                  <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                    Your Email Address
                  </Form.Label>
                  <Form.Control
                    className="eligibility-placeholder"
                    type="email"
                    name="eligibility.email"
                    value={values.eligibility!.email as string}
                    onChange={handleEmailChange}
                    placeholder="Enter email address"
                    isInvalid={
                      getIn(touched, "eligibility.email") &&
                      getIn(errors, "eligibility.email")
                    }
                    onBlur={handleBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {getIn(errors, "eligibility.email")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <br></br>
              <Row className="eligibility-form__row">
                <Form.Group controlId="formBasicCheckboxPrivacyPolicy">
                  <Row
                    className="eligibility-form__row"
                    style={{ marginLeft: "-9px" }}
                  >
                    <Col
                      xs={1}
                      style={{
                        width: "20px",
                        paddingTop: "4px",
                        marginRight: "0",
                      }}
                    >
                      <Form.Check
                        id="privacy"
                        value="true"
                        name="eligibility.privacyPolicy"
                        checked={handlePrivacy === true}
                        inline
                        style={{
                          paddingLeft: 0,
                        }}
                        onChange={() => setHandlePrivacy(!handlePrivacy)}
                      />
                    </Col>
                    <Col style={{ padding: 0, margin: 0 }}>
                      <span style={{ fontSize: "11px", color: "#696969" }}>
                        <span
                          style={{
                            marginLeft: "15px",
                            color: "#D9534F",
                            fontSize: "16px",
                          }}
                          className="required-field-marker"
                        >
                          *
                        </span>
                        I have read and agree to the{" "}
                        <a
                          href="/terms"
                          target="_blank"
                          rel="noreferrer"
                          className="a-link"
                        >
                          Terms of Use
                        </a>{" "}
                        and{" "}
                        <a
                          href="/privacy-policy"
                          target="_blank"
                          rel="noreferrer"
                          className="a-link"
                        >
                          Privacy Policy
                        </a>
                      </span>
                    </Col>
                  </Row>
                  <Row className="eligibility-form__row"></Row>
                </Form.Group>
              </Row>
              <Row className="eligibility-form__row">
                <Form.Group controlId="formBasicCheckboxBcbsaAgreement">
                  <Row
                    className="eligibility-form__row"
                    style={{ marginLeft: "-9px" }}
                  >
                    <Col
                      xs={1}
                      style={{
                        width: "20px",
                        paddingTop: "4px",
                        marginRight: "0",
                      }}
                    >
                      <Form.Check
                        id="bcbsaAgreement"
                        value="true"
                        name="eligibility.bcbsaAgreement"
                        checked={handleBCBSAAgreement === true}
                        inline
                        style={{
                          padding: 0,
                        }}
                        onChange={() =>
                          setHandleBCBSAAgreement(!handleBCBSAAgreement)
                        }
                      />
                    </Col>
                    <Col style={{ padding: 0, margin: 0 }}>
                      <span style={{ fontSize: "11px", color: "#696969" }}>
                        <span
                          style={{
                            marginLeft: "15px",
                            color: "#D9534F",
                            fontSize: "16px",
                          }}
                          className="required-field-marker"
                        >
                          *
                        </span>
                        I agree to share my contact information with Blue Cross
                        and Blue Shield Association
                      </span>
                    </Col>
                  </Row>
                  <Row className="eligibility-form__row"></Row>
                </Form.Group>
              </Row>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                bsPrefix="btn font-weight-bold modal-footer-form__button"
                style={{ width: `77%`, fontSize: "16px" }}
                onClick={handleJWTSubmit}
                disabled={
                  !handleBCBSAAgreement ||
                  !handlePrivacy ||
                  !isValidEmail(values.eligibility!.email)
                }
              >
                {"Confirm & Continue To Next Step"}
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "NOEMAIL":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__exclamation"></div>
              <h2>
                <b>Email Address Unavailable</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">{message}</Modal.Body>
            <br></br>
            <br></br>
            <Modal.Footer className="modal-footer-form">
              <Button
                bsPrefix="btn font-weight-bold modal-footer-form__close_button"
                style={{ whiteSpace: "nowrap" }}
                onClick={onCancel}
              >
                Close
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                bsPrefix="btn font-weight-bold modal-footer-form__button"
                style={{ whiteSpace: "nowrap" }}
                onClick={onCancel}
              >
                Enter New Email
              </Button>
            </Modal.Footer>
            <br></br>
          </Fragment>
        );
      case "REDEMPTION":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__checkmark"></div>
              <h2 className="redemption-eligibility">
                <b>Welcome Blue365 Members</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">
              <h6 className="redemption-confirmed">
                Your Eligibility has been confirmed!
              </h6>
              <p>
                <small className="redemption-agreement">
                  Explore our different packages, find participating gyms, and
                  discover more perks that come with a Fitness Your Way
                  membership.
                </small>
              </p>
              <p style={{ marginTop: "10px" }}>
                <small className="redemption-proceed">
                  Please agree to our terms before proceeding.
                </small>
              </p>
              <p style={{ marginBottom: "-20px" }}>
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    onChange={checked}
                    style={{
                      marginRight: "6px",
                      height: "13px",
                      width: "13px",
                    }}
                  />
                  <small className="redemption-tnc">
                    <span className="required-field-marker">*</span>I have read
                    and agree to the{" "}
                    <a
                      href="/terms"
                      target="_blank"
                      rel="noreferrer"
                      className="a-link"
                    >
                      Terms of Use
                    </a>{" "}
                    and{" "}
                    <a
                      href="/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                      className="a-link"
                    >
                      Privacy Policy
                    </a>
                  </small>
                </label>
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="primary"
                className="redemption-button"
                disabled={!check}
                onClick={onNext}
              >
                Explore
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "EXISTS":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__exclamation"></div>
              <h2 className="redemption-eligibility">
                <b>Existing Account Found</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">
              <p>
                <small className="redemption-agreement">
                  Our records indicate that you already have an account with us.
                  Please{" "}
                  <a
                    onClick={() => loginWithRedirect()}
                    style={{
                      textDecoration: `underline`,
                      color: "var(--primary)",
                      cursor: `pointer`,
                    }}
                  >
                    login
                  </a>{" "}
                  or{" "}
                  <a
                    onClick={() => loginWithRedirect()}
                    style={{
                      textDecoration: `underline`,
                      color: "var(--primary)",
                      cursor: `pointer`,
                    }}
                  >
                    reset your password
                  </a>{" "}
                  to access your account.
                </small>
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                bsPrefix="btn font-weight-bold modal-footer-form__close_button"
                style={{ whiteSpace: "nowrap" }}
                onClick={onCancel}
              >
                Close
              </Button>
              <Button
                bsPrefix="btn font-weight-bold modal-footer-form__button"
                style={{ whiteSpace: "nowrap" }}
                onClick={() => loginWithRedirect()}
              >
                Login
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "EXISTS_NO_BUTTON":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__exclamation"></div>
              <h2 className="redemption-eligibility">
                <b>Existing Account Found</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">
              <p>
                <small className="redemption-agreement">
                  Our records indicate that you already have an account with us.
                  Please{" "}
                  <a
                    onClick={() => loginWithRedirect()}
                    style={{
                      textDecoration: `underline`,
                      color: "var(--primary)",
                      cursor: `pointer`,
                    }}
                  >
                    login
                  </a>{" "}
                  or{" "}
                  <a
                    onClick={() => loginWithRedirect()}
                    style={{
                      textDecoration: `underline`,
                      color: "var(--primary)",
                      cursor: `pointer`,
                    }}
                  >
                    reset your password
                  </a>{" "}
                  to access your account.
                </small>
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap" }}
                onClick={() => loginWithRedirect()}
              >
                Login
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "ALREADY_CONFIRMED":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__checkmark"></div>
              <h2>
                <b>Eligibility Already Confirmed!</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">
              <h6>Please Proceed to the next step!</h6>
              <p>
                <small>
                  You&apos;re one step closer to a longer, healthier life.
                </small>
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                bsPrefix="btn font-weight-bold modal-footer-form__button"
                onClick={onNext}
              >
                Proceed to Next Step
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "BURNALONG":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form-partner">
              <h2>
                <b>You're heading to BurnAlong.com</b>
              </h2>
              <span style={{ cursor: "pointer" }} onClick={onCancel}>
                X
              </span>
            </Modal.Header>
            <Modal.Body className="modal-body-form-partner">
              <h6>
                You're leaving the Fitness Your Way portal and being redirected
                to our friends at BurnAlong.com
              </h6>
              <p>
                <small>
                  Please note that BurnAlong.com is not owned, operated or
                  controlled by Tivity Health, inc. Or it's affiliates. By
                  visiting BurnAlong.com, you will be subject to their terms of
                  use and privacy policy
                </small>
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form-partner">
              <Button
                variant="outline-primary"
                className="font-weight-bold modal-footer-form-partner__button stay-here"
                style={{ whiteSpace: "nowrap" }}
                onClick={onCancel}
              >
                Stay Here
              </Button>
              <Button
                variant="primary"
                className="font-weight-bold modal-footer-form-partner__button"
                onClick={onNext}
              >
                Visit BurnAlong
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "ZUORA":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form-partner">
              <h2>
                <b>You're heading to Zuora.com</b>
              </h2>
              <span style={{ cursor: "pointer" }} onClick={onCancel}>
                X
              </span>
            </Modal.Header>
            <Modal.Body className="modal-body-form-partner">
              <h6>
                You're leaving Fitness Pass and being redirected to our friends
                at Zuora.com
              </h6>
              <p>
                <small>
                  Please note that Zuora.com is not owned, operated or
                  controlled by Tivity Health, inc. Or it's affiliates. By
                  visiting Zuora.com, you will be subject to their terms of use
                  and privacy policy
                </small>
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form-partner">
              <Button
                variant="outline-primary"
                className="font-weight-bold modal-footer-form-partner__button stay-here"
                style={{ whiteSpace: "nowrap" }}
                onClick={onCancel}
              >
                Stay Here
              </Button>
              <Button
                variant="primary"
                className="font-weight-bold modal-footer-form-partner__button"
                onClick={onNext}
              >
                Visit Zuora
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      default:
        return null;
    }
  };
  return (
    <Modal centered show={show} dialogClassName="status-modal">
      {getContent()}
    </Modal>
  );
};

export default StatusModal;
